
import { useEffect, useState } from "react";
import { decryptData, defaulUrl, formatMoney, onfocus } from "../components/const";
import UseFetch from "../components/UseFetch";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
const EditeFacture = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const facture = location.state.facture
    let id = decryptData('user_profile').idmagasin
    let { data: produitsConteneur, isloading } = UseFetch(`conteneur/${facture?.idconteneur}/stocks`);
    const { data: details, isloading: load } = UseFetch(`factures/${facture.idfacture}`);
    const produits = details?.produits;
    const paiements = details?.paiements
    const [gonext, setGonext] = useState(true);
    const [isLoading, setIsloading] = useState(false);
    const [total, setTotal] = useState(0);
    const [remise, setRemise] = useState(facture?.remise);
    const [net, setNet] = useState(0);
    const [paye, setPaye] = useState(facture?.paye);
    const [sourceSolde, setsourceSolde] = useState(false);
    const [selected, setSelect] = useState(null);
    const handSelect = (prod) => {
        if (!(selected.find(p => (p.idproduit === prod.idproduit)))) {
            setSelect([
                ...selected,
                {
                    'idproduit': prod.idproduit,
                    'produit': prod.produit,
                    'quantite': 1,
                    'pvu': prod.pvu,
                    'idstock': prod.id,
                    'qte_total': +prod.stock,
                    'idconteneur': prod.idconteneur
                }
            ]);
        }
    }
    const handleTotal = () => {
        setTotal(selected?.reduce(
            (previousValue, currentValue) => { return previousValue + currentValue.quantite * currentValue.pvu },
            0))
    }
    console.log(facture)
    const handleSave = async () => {
        let badIndex = -1;
        selected.forEach(prod => {
            if (prod.quantite > prod.qte_total) {
                badIndex = +selected.indexOf(prod) + 1
            }
        });

        if (badIndex >= 0) {
            Swal.fire({
                title: 'Quantite insuffisante',
                text: `Vous avez saisi une quantite superieure au stock sur la ligne ${badIndex}`,
                icon: "error"
            })
            return
        }
        let fact = {
            'montant': total,
            'remise': remise,
            'montant_net': net,
            'paye': paye,
            'source': sourceSolde,
            'idmagasin': id,
            'idconteneur': facture?.idconteneur
        }
        if (total <= remise) {
            Swal.fire({
                title: "Erreur sur la remise!",
                text: "Vérifiez le montant de la remise!",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            })
            return
        }
        if (net < paye) {
            Swal.fire({
                title: "Montant trop élévé!",
                text: "Vous avez saisi un montant supérieur au montant net à payer!",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            })
            return
        }
        setIsloading(true);
        let status = 0
        fetch(`${defaulUrl}edite/${facture?.idfacture}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            },
            body: JSON.stringify({ fact, selected })
        }).then(response => {
            status = response.status;
            return response.json();
        }).then(data => {
            console.log(data)
            if (status === 200) {
                Swal.fire({
                    title: "Succès",
                    text: data.message,
                    icon: "success"
                }).then(() => {
                    handleReset();
                    navigate(-1);
                });
            } else {
                Swal.fire({
                    title: 'Erreur',
                    text: data.message,
                    icon: 'error'
                })
            }
            setIsloading(false);
        })
    }
    const handleQte = (e, prod) => {
        prod.quantite = +e.target.value
        handleTotal()

    }
    useEffect(() => {
        if (!selected) {
            setSelect(produits);
        }
    }, [produits])
    useEffect(() => {
        if (!selected) {
            setSelect(produits);
        }
    }, [paiements])
    useEffect(() => {
        handleTotal();
    }, [selected])
    useEffect(() => {
        setNet(total - remise)
    }, [selected, total])

    const handleReset = () => {
        setTotal(0);
        setRemise(0);
        setNet(0);
        setPaye(0);
        setSelect([]);
        setGonext(false);
        setIsloading(false);
        setsourceSolde(false);
    }



    return (

        isloading ? <div className="loader"></div> : <div className="edite">
            {isLoading && <div className="loader"></div>}
            {(selected?.length > 0) && <div className="fst-itaclic h6 text-muted">{selected?.length} produit(s) sélectionné(s)</div>}
            {!gonext && (
                <div className="row">

                    <h4 className="text-secondary text-center">MODIFIER UNE FACTURE</h4>
                    <div className="row justify content-between align-items-center">
                        <div className="col-lg-6 h5 text-primary">Choix des produits</div>
                    </div>
                    {
                        <div className="row"  >
                            <div className="card my-2 p-1" >
                                <div className="card-header">
                                    <div className="card-title text-primary text-uppercase">{`${facture?.idconteneur} - ${facture?.conteneur}`}</div>
                                </div>
                                <div className="card-body ">
                                    <div className="row cont">
                                        <div className="row p-2 ">
                                            {
                                                produitsConteneur?.map((prod, index) => (
                                                    <div key={index} className="col-sm-3  shadow p-1 radius-15 m-0 prod">
                                                        <div className="card h-100 p-2 border-0 d-flex flex-column justify-content-center align-items-center m-0">
                                                            <span className="text-center prod-name">{prod.produit}</span>
                                                            <small className="fw-bold text-secondary">Qte: {prod.stock}</small>
                                                            <strong className="prix text-center">{`${prod?.pvu?.toLocaleString()} GNF`}</strong>
                                                            <button className="mt-auto btn btn-outline-success  justify-self-end" onClick={() => handSelect(prod)} >Ajouter</button>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    }
                </div>


            )}

            {gonext && <div className="row">
                <fieldset className="border rounded bg-dark-subtle">
                    <div className="row px-4 my-2" >
                        <label htmlFor="client" className="fs-4" >Client</label>
                        <div className="form-group  col-lg-6 d-flex gap-1 align-items-center ">
                            {facture?.client?.toUpperCase()}
                            ({facture?.telephone?.toUpperCase()})
                        </div>
                        {(+facture?.solde > net) && <div className="col-lg-5 d-flex gap-1 px-2  ">
                            <input type="checkbox" name="" id="ck" className="form-check-input" onChange={(e) => setsourceSolde(e.target.checked)} />
                            <label className="text-primary fs-6" htmlFor="ck"> Utiliser le solde du client comme moyen de paiement </label>
                        </div>}
                    </div>
                </fieldset>
                <div className="table-responsive p-2">
                    <table className="table table-white table-hover table-striped">
                        <thead className="table-primary">
                            <tr>
                                <th>N°</th>
                                <th>PRODUITS</th>
                                <th>QTE STOCK</th>
                                <th>QTE</th>
                                <th>PVU</th>
                                <th>MONTANT</th>
                                <th>ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                selected?.map((prod, ind) => (
                                    <tr key={ind} className="align-middle">
                                        <td>{ind + 1}</td>
                                        <td>{prod.produit}</td>
                                        <td>{prod.qte_total}</td>
                                        <td>
                                            <input type="number" min={1} max={prod.qte_total} className="form-control" defaultValue={+prod.quantite} onChange={(e) => handleQte(e, prod)} />
                                        </td>
                                        <td>
                                            <div className="input-group">
                                                <input type="text" className="form-control"
                                                    placeholder="Entrez le prix unitaire"
                                                    defaultValue={prod.pvu?.toLocaleString()}
                                                    onKeyUp={() => handleTotal()}
                                                    onChange={(e) => prod.pvu = +(e.target.value.replace(/\D/g, ""))}
                                                    onBlur={(e) => formatMoney(e)}
                                                    onFocus={(e) => onfocus(e)} />
                                                <span className="input-group-text" id="basic-addon2">GNF</span>
                                            </div>
                                        </td>
                                        <td>{(prod.pvu * prod.quantite)?.toLocaleString()} GNF</td>

                                        <td className="text-end ">
                                            <i className="bx bx-trash mx-auto a p-1  m-1 bg-danger-subtle 
                                            rounded m-1 text-danger fs-5"
                                                onClick={() => {
                                                    setSelect(selected.filter(p => (p !== prod)))
                                                }}></i>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                <div className="row p-2 ms-1 bg-secondary-subtle align-items-center">
                    <div className="col-lg-3 ">
                        <div className="form-group">
                            <label htmlFor="">  Total</label>
                            <input type="text" disabled className="form-control" value={`${total?.toLocaleString()} GNF`} />
                        </div>
                    </div>
                    <div className="col-lg-3 ">
                        <div className="form-group ">
                            <label htmlFor=""> Remise</label>
                            <div className="input-group ">
                                <input type="text" className="form-control" defaultValue={remise} placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2"
                                    onChange={(e) => {
                                        setRemise(e.target.value.replace(/\s/g, ""));
                                        setNet(+total - (+e.target.value.replace(/\s/g, "")))
                                    }}
                                    onBlur={(e) => formatMoney(e)} />
                                <span className="input-group-text" id="basic-addon2">GNF</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 ">
                        <div className=" form-group">
                            <label htmlFor=""> Net A payer:</label>
                            <input type="text" disabled className="form-control" value={`${net?.toLocaleString()} GNF`} />
                        </div>
                    </div>
                    <div className="col-lg-3 ">
                        <div className="form-group ">
                            <label htmlFor="">  Payé:</label>
                            <div className="input-group">
                                <input type="text" className="form-control" defaultValue={paye}
                                    onChange={(e) => {
                                        setPaye(e.target.value.replace(/\s/g, ""));
                                    }}
                                    onBlur={(e) => formatMoney(e)} />
                                <span className="input-group-text" id="basic-addon2">GNF</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            }
            <div className="row justify-content-end p-2 mt-5">
                <div className=" modal-footer">
                    {(!gonext && selected?.length > 0) &&
                        <button className="btn btn-primary m-1 btn-card " onClick={() => setGonext(true)}>
                            <i className="bx bx-cart-alt fs-1 " ></i>
                            <span className="text-warning p-1 m-auto">{selected?.length}</span>
                        </button>
                    }
                    {gonext && <button className="btn btn-danger m-1"
                        onClick={() => {

                            setGonext(false)
                        }}> <i className="bx bx-first-page"></i> Retour
                    </button>}
                    {(selected?.length > 0 && gonext) && <button className="btn btn-success m-1" onClick={() => handleSave()} disabled={isloading}>Enregistrer</button>}
                </div>

            </div>
        </div>
    );
}

export default EditeFacture;