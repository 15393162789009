
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { decryptData, defaulUrl } from "../components/const";
const Pointage = () => {

    const [liste, setList] = useState([]);
    const [lesPointes, setLesPointes] = useState([])
    const [datejour, setDateJour] = useState((new Date()).toISOString().slice(0, 10));
    const [date1, setDate1] = useState(datejour);
    const [load, setLoad] = useState(false)
    const handleSetPresence = (pers) => {
        if (pers?.presence && !lesPointes.includes(pers)) {
            setLesPointes([...lesPointes, pers])
        }
    }
    const handlePointer = (pers = null) => {

        if (pers) {
            if (!pers.presence) {
                Swal.fire({
                    title: "Erreur",
                    text: "Vous n'avez pas choisi une option!",
                    icon: 'error'
                })
                return
            }
            setLesPointes([]);
            setLesPointes([...lesPointes, pers])
        }

        if (!lesPointes.length) {
            Swal.fire({
                title: "Liste vide",
                text: "Pointez au moins un élément!",
                icon: 'error'
            })
            return
        }
        let status = 0;
        setLoad(true)
        fetch(`${defaulUrl}personnel/pointer`, {
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + decryptData('ACCESS_TOKEN'),
                Accept: 'application/json',
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "presents": lesPointes,
                "date": date1
            })
        }).then((response) => {
            status = response.status;
            return response.json();

        }).then(data => {
            setLoad(false)
            console.log(data)
            if (status === 200) {
                Swal.fire({
                    title: "Pointage",
                    text: "Operation effectuee",
                    icon: 'success'
                })
            }
        })
    }
    const handleRrefreshList = () => {
        let status = 0;
        setLoad(true)
        fetch(`${defaulUrl}liste-pointage/${date1}`, {
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + decryptData('ACCESS_TOKEN'),
                Accept: 'application/json',
                "Content-Type": "application/json"
            },
        }).then((response) => {
            status = response.status;
            return response.json();
        }).then(data => {
            setLoad(false);
            if (status === 200) {
                setList(data);

            }
        })
    }
    useEffect(() => {
        handleRrefreshList()
    }, [])
    return (
        <div className="table-responsive mt-2">
            {load && <div className="loader"></div>}
            <div className="row justify-content-between align-items-center mb-2">
                <div className="col-sm-2">
                    <label htmlFor="" className="form-label">Date</label>
                    <div className="input-group">
                        <input type="date" className="form-control " id="date1" name="date1"
                            defaultValue={date1} onChange={(e) => setDate1(e.target.value)}
                            onBlur={() => setList([])}
                        />
                        <button className="btn btn-primary w-auto"
                            onClick={handleRrefreshList}
                        ><i className="bx bx-refresh"></i></button>
                    </div>
                </div>
                <div className="text-end text-primary h4 col-lg-4">FEUILLE DE PRESENCE</div>
            </div>

            <table className="table table-striped table-bordered table-hover">
                <thead className="table-dark">
                    <tr className="align-middle text-center">
                        <th>N°</th>
                        <th>PRENOMS & NOM</th>
                        <th>TELEPHONE</th>
                        <th>FONCTION</th>
                        <th>MAGASIN</th>
                        <th>PRESENCE</th>
                    </tr>
                </thead>
                <tbody className="plage">
                    {
                        liste?.map((pers, ind) => (
                            <tr className="align-middle tr" key={ind}>
                                <td>{ind + 1}</td>
                                <td className="critere">{pers.nom?.toUpperCase()}</td>
                                <td>{pers.phone}</td>
                                <td>{`${pers.droit === 'Magasin' ? 'Vendeur' : pers.droit}`}</td>
                                <td>{pers.magasin?.magasin?.toUpperCase()}</td>
                                <td className="text-end">
                                    {/* <div className="input-group"> */}
                                    <select name="" id="" className="form-select" defaultValue={pers.presence}
                                        onChange={(e) => {
                                            pers.presence = e.target.value
                                            handleSetPresence(pers)
                                        }}>
                                        <option value=""></option>
                                        <option value="Présent">Présent</option>
                                        <option value="Absent(justifié)">Absent(justifié)</option>
                                        <option value="Absent(non justifié)">Absent(non justifié)</option>
                                        <option value="Permission">Permission</option>
                                        <option value="En mission">En mission</option>
                                        <option value="Malade">Malade</option>
                                    </select>

                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>

            <button type="button" className="btn btn-success  px-5 rounded-5 shadow position-fixed"
                onClick={() => handlePointer()} disabled={load}>Enregistrer tout</button>

        </div>
    );
}

export default Pointage;