import { useState } from "react";
import { decryptData, defaulUrl, formatMoney, Loading, onfocus } from "../components/const";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";

const ModalRemboursement = ({ table }) => {
    const location = useLocation();
    const facture = location.state.facture
    const [paye, setPaye] = useState(0)
    const [errors, setErrors] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [sourceSolde, setsourceSolde] = useState(false);
    const navigation = useNavigate();
    const handlePayer = (e) => {
        e.preventDefault();
        if (paye < 1000 || paye === null) {
            setErrors({ 'montant': 'Entrez une valeure superieure ou egale a 1 000 GNF!' });
            return
        }
        if (paye > (+facture?.reste)) {
            setErrors({ 'montant': 'Vous avez saisi un montant trop élévé!' });
            return
        }
        if (sourceSolde && paye > (+facture?.solde)) {
            setErrors({ 'montant': `Le solde du clietn(${facture?.solde?.toLocaleString()}) est insuffisant!` });
            return
        }
        let status = 0;
        setIsLoading(true)
        const formData = new FormData();
        formData.set('numero', facture?.numero)
        formData.set('table', table)
        formData.set('montant', +paye)
        formData.set('source', sourceSolde);
        fetch(`${defaulUrl}payer/${facture?.numero}`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                ContentType: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            },
            body: formData
        }).then((response) => {
            status = response.status;
            return response.json()
        }).then(data => {
            setIsLoading(false);
            if (status == 200) {
                Swal.fire({
                    title: 'Succès',
                    text: data.message,
                    icon: 'success'
                }).then(() => {
                    setPaye(0);
                    setsourceSolde(false)
                })
                setErrors(null);
                navigation(-1);
            } else {
                setErrors(data.errors);
            }
        })
        setErrors(null);
    }
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-5 card p-0">
                    <div className="card-header ">
                        <h5 className="card-title  text-center" >PAIEMENT DE FACTURE</h5>
                    </div>

                    <form >
                        <div className="card-body position-relative">
                            <div className="form-group">
                                <label htmlFor="" className="fs-5">Client: </label>
                                {facture?.client}
                            </div>
                            <div className="form-group">
                                <label htmlFor="" className="fs-5">Téléphone: </label>
                                {facture?.telephone}
                            </div>
                            <hr />
                            <div className="form-group">
                                <label htmlFor="" className="fs-5">Montant à payer</label>
                                <div className="form-control hide-arrow p-2 bg-secondary-subtle">{facture?.reste?.toLocaleString()} GNF</div><div />
                            </div>
                            <label htmlFor="" className="fs-5">Montant payé</label>
                            <div className="form-group">
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" defaultValue={paye}
                                        onChange={(e) => {
                                            setPaye(e.target.value.replace(/\s/g, ""));
                                        }}
                                        onBlur={(e) => formatMoney(e)}
                                        onFocus={(e) => onfocus(e)}
                                    />
                                    <span className="input-group-text" id="basic-addon2">GNF</span>
                                </div>
                                {errors?.montant && <span className="text-danger p-1">{errors?.montant}</span>
                                }
                            </div>
                            <div className="modal-footer">
                                <input type="checkbox" name="" id="ck" className=" bg-danger w-fit-content" onChange={(e) => setsourceSolde(e.target.checked)} />
                                <label className="text-primary" htmlFor="ck"> Utiliser le solde du client </label>
                            </div>
                            {isLoading && <Loading />}
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success m-1" onClick={(e) => handlePayer(e)} disabled={isLoading}>Valider & quiter  </button>
                        </div>
                    </form>

                </div>
            </div>
        </div>


    );
}

export default ModalRemboursement;