import { useRef, useState } from "react";
import { Loading, decryptData, defaulUrl } from "../components/const";
import Swal from "sweetalert2";
const ModalAddProduct = () => {
    let [produit, setProduit] = useState('');
    let [errors, setErrors] = useState({});
    const useref = useRef()
    let [isLoading, setIsLoading] = useState(false);
    const handleSubmite = (e) => {
        let status = 0
        setIsLoading(true)
        e.preventDefault();
        const formdata = new FormData();
        formdata.set('produit', produit);
        fetch(`${defaulUrl}produits/add`, {
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + decryptData('ACCESS_TOKEN'),
                Accept: 'application/json'
            },
            body: formdata
        }).then((response) => {
            status = response.status;
            return response.json();
        }).then(data => {
            setIsLoading(false)
            if (status === 200) {
                setIsLoading(false);
                Swal.fire({
                    title: "Succès",
                    text: data.message,
                    icon: "success"
                }).then(close => {
                    useref.current.click()
                    setProduit('')
                })

                setErrors({});
            } else {
                setErrors(data.errors)
            }
        })



    }
    return (

        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Ajouter un produit</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form className=" p-2 rounded " onSubmit={handleSubmite} >
                            <div className="row my-2">
                                <div className="col-12 p-1">
                                    <div className="form-group">

                                        <label htmlFor="produit" className=" fs-4 fw-800 text-dark">Libelé Produit</label>
                                        <input type="text" name="produit" id="produit" className="form-control p-3" placeholder="Entrez le libele du produit" onChange={(e) => setProduit(e.target.value)} value={produit} />
                                    </div>
                                </div>
                                {errors.produit && <span className="text-danger">{errors.produit}</span>}
                            </div>

                            <div className="row justify-content-end align-items-center p-2 text-end m-2">
                                <div className="col-lg-6 d-flex justify-content-end">
                                    <a ref={useref} data-bs-dismiss="modal" className="btn btn-danger">Annuler</a>
                                    <button type="submit" className="btn btn-success mx-2 d-flex align-items-center " disabled={isLoading}>{isLoading && <Loading />} Enregistrer</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>


    );
}

export default ModalAddProduct;