import { useEffect, useRef, useState } from "react";
import { decryptData, defaulUrl, Loading } from "../components/const";
import UseFetch from "../components/UseFetch";
import Swal from "sweetalert2";
import Select from "react-select";
const Messagerie = () => {
    const { data, isloading } = UseFetch('messages');
    const { data: clients, isloading: load } = UseFetch('clients');
    const soldeSMs = data?.solde;
    const message = data?.message;
    const [isLoading, setisLoading] = useState(false)
    const ref = useRef();
    const [sms, setSms] = useState(null)
    const [selected, setSelected] = useState(null)
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [tous, setTous] = useState(false)
    const [contacts, setContacts] = useState([]);
    const handleEdite = (e) => {
        e.preventDefault()
        if (!selected?.message) {
            return;
        }
        let status = 0;
        const formdata = new FormData(e.target)
        formdata.append('id', selected?.id)
        formdata.append('titre', selected?.titre)
        setisLoading(true)
        fetch(`${defaulUrl}message/edite`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            },
            body: formdata
        }).then(response => {
            setisLoading(false)
            status = response.status
            return response.json()
        }).then(data => {
            if (status === 200) {
                Swal.fire({
                    title: "succès",
                    text: data?.message,
                    icon: 'success'
                })
            } else {
                Swal.fire({
                    title: "error",
                    text: data?.message,
                    icon: 'error'
                })
            }
        })
    }

    const senMessage = () => {
        let status = 0;
        Swal.fire({
            title: "Envoi de message",
            text: "Souhaitez-vous envoyer ce message de groupe? Cela pourait prendre plusieurs minutes!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: "OUI",
            cancelButtonText: "Annuler"
        }).then(confim => {
            if (confim.isConfirmed) {
                setisLoading(true)
                fetch(`${defaulUrl}message/rappel`, {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`,
                        Accept: 'Application/json'
                    }
                }).then(response => {
                    status = response.status;
                    return response.json()
                }).then(data => {
                    setisLoading(false)
                    if (status === 200) {
                        Swal.fire({
                            title: "Messages envoyés",
                            text: data?.message,
                            icon: 'success'

                        })
                    } else {
                        Swal.fire({
                            title: "Messages non envoyés",
                            text: data?.error,
                            icon: 'error'

                        })
                    }
                })
            }
        })
    }
    const sendCostunMessage = (e) => {
        e.preventDefault()
        if (!tous && selectedOptions.length < 1) {
            Swal.fire({

                title: "Aucun contact ajouté",
                text: "Veuillez ajouter au moins un contact",
                icon: "error"
            })
            return
        }
        if (!sms) {
            Swal.fire({

                title: "Envoi impossible",
                text: "Impossible d'envoyer un message vide!",
                icon: "error"
            })
            return
        }
        let status = 0;
        setisLoading(true)
        fetch(`${defaulUrl}message/costum`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "message": sms,
                "tous": tous,
                contacts
            })
        }).then(response => {
            status = response.status;
            return response.json()
        }).then(data => {
            setisLoading(false)
            if (status === 200) {
                Swal.fire({
                    title: "Messages envoyés",
                    text: data?.message,
                    icon: 'success'

                })
            } else {
                Swal.fire({
                    title: "Messages non envoyés",
                    text: data?.error,
                    icon: 'error'

                })
            }
        })
    }
    useEffect(() => {
        setContacts(selectedOptions.map((clt) => (clt.value)))
    }, [selectedOptions])
    return (
        isloading ? <div className="loader"></div> : <div className="messages">
            <h4 className="text-primary">GESTION DES MESSAGES</h4>
            <div className="text-end py-2" >
                <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modal-envoi">Envoyer un message personnalisé</button>
            </div>
            <div className="row my-2">
                {
                    message?.map((mes, ind) => (
                        <div className="col-lg-3 p-2" key={ind}>
                            <div className="card h-100 m-1">
                                <div className="card-header text-primary row w-100 m-0 px-1">{mes.titre}<button className="w-auto btn btn-primary ms-auto justify-self-end" data-bs-toggle="modal" data-bs-target="#modal-sms" onClick={() => setSelected(mes)}>
                                    <i className="bx bx-edit"></i> </button>
                                </div>
                                <div className="card-body">
                                    {mes.message}
                                </div>

                                {mes.titre === 'Message rappel' && <div className="card-footer text-end">
                                    <button className="btn btn-primary" onClick={() => senMessage()}>Envoyer les rappels</button>
                                </div>
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className="shadow text-secondary h4 p-5 rounded">VOTRE SOLDE SMS EST: <span className={soldeSMs > 10 ? 'text-success fs-4' : 'text-danger fs-4'}>{soldeSMs?.toLocaleString()}</span> <div className="text-end"><a href="https://www.nimbasms.com/app/packs" className="text-decoration-underline" target="_blank">Achat sms</a></div></div>

            {/* MODAL MODIFICATION MESSAGE */}
            <div className="modal fade " id="modal-sms" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header bg-secondary-subtle">
                            <h1 className="modal-title fs-5" >Modification message</h1>
                            <button type="button " className="btn-close btn-danger" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={(e) => handleEdite(e)} >
                            <div className="modal-body position-relative">


                                <textarea name="message" id="message" rows={5} cols="30"
                                    placeholder="Ecrire le message ici"
                                    className="form-control"
                                    defaultValue={selected?.message}
                                    onChange={(e) => selected.message = e.target.value}
                                >{selected?.message}</textarea>
                                {isLoading && <div className="text-center p-2">
                                    <Loading />
                                </div>}
                            </div>
                            <div className="modal-footer">
                                <a className="btn btn-danger" data-bs-dismiss="modal" ref={ref}>Annuler</a>
                                <button type="submit" className="btn btn-success" disabled={isLoading} >Modifier</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
            {/* MODAL ENVOI PERSONNALISE MESSAGE */}
            <div className="modal modal-lg fade " id="modal-envoi" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header bg-secondary-subtle">
                            <h1 className="modal-title fs-5" >Envoyer un message</h1>
                            <button type="button " className="btn-close btn-danger" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={(e) => sendCostunMessage(e)} >
                            <div className="modal-body position-relative">
                                <div className="form-group my-2">
                                    <label htmlFor="">Destinataires(vous pouvez choisir plusieurs) <span className="ms-auto"> <input type="checkbox" className="fomr-check-input" id="tous" name='tous' onChange={(e) => setTous(!tous)} /> <label htmlFor="tous" className="text-primary">Envoyer a tous</label> </span></label>
                                    <Select isDisabled={tous}
                                        options={
                                            clients?.map((client) => (
                                                {
                                                    value: `${client.telephone}`, label: `${client.client}(${client.telephone})`
                                                }
                                            ))
                                        }
                                        isMulti
                                        onChange={setSelectedOptions}

                                        classNamePrefix="react-select"
                                    />
                                </div>
                                <textarea name="message" id="message" rows={5} cols="30"
                                    placeholder="Ecrire le message ici"
                                    className="form-control"
                                    defaultValue={selected?.message}
                                    onChange={(e) => setSms(e.target.value)}
                                >{sms}</textarea>
                                {isLoading && <div className="text-center p-2">
                                    <Loading />
                                </div>}
                            </div>
                            <div className="modal-footer">
                                <a className="btn btn-danger" data-bs-dismiss="modal" ref={ref}>Annuler</a>
                                <button type="submit" className="btn btn-success" disabled={isLoading}> Envoyer</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>


            {isLoading && <div className="loader"></div>}
        </div >

    );
}

export default Messagerie;