import { useLocation, useNavigate} from "react-router-dom";
import { impression } from "../components/const";
import { useRef } from "react";
const DetailAppro = () => {
    const navigator = useNavigate()
    const location = useLocation();
    const table = location.state.appro?.produits;
    const total_qte = (table?.reduce((previous, current) => { return previous + current.quantite }, 0));
    const total_pau = (table?.reduce((previous, current) => { return previous + ((+current.pau) * (+current.quantite)) }, 0));
    const total_pvu = (table?.reduce((previous, current) => { return previous + ((+current.pvu) * (+current.quantite)) }, 0));
    const total_qte_vendu = (table?.reduce((previous, current) => { return previous + current.vendu }, 0));
    const printElement = useRef();
    return (
        <div className="AddModal">
            <div className="row justify-content-between">
                <div className="col-lg-3">
                    <button className="btn btn-primary" onClick={() => navigator(-1)}  ><i className="bx bx-arrow-back"></i> Retour</button>
                </div>
                <div className="h3 text-primary col-lg-6 text-end">LISTE DES PRODUITS DU CONTENEUR</div>

            </div>
            <div className="row">
                <div className="w-auto ms-auto">
                    <button className="btn btn-primary" onClick={() => impression(printElement.current, 'landscape', `LISTE DES PRODUITS-CONTENEUR: ${location?.state?.appro.conteneur?.toUpperCase()}`)}><i className="bx bx-printer"></i></button></div>
                <div className="row p-2 table-responsive mt-2" ref={printElement}>

                    <table className="table table-striped table-hover shadow table-bordered border-primary">
                        <thead className="table-dark border-primary">
                            <tr className="text-center">
                                <th>N°</th>
                                <th>Produit</th>
                                <th >PAU</th>
                                <th>Qte appro</th>
                                <th>Mont. Achat</th>
                                <th >PVU</th>
                                <th>Mont. Vente</th>
                                <th>qte vendu</th>
                                <th>qte restante</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                table?.map((prod, index) => (
                                    <tr key={index + 1}>
                                        <td>
                                            {index + 1}
                                        </td>
                                        <td>{prod.produit}</td>
                                        <td className="bg-warning-subtle">{`${(prod?.pau?.toLocaleString())} GNF`}</td>
                                        <td>{prod.quantite}</td>
                                        <td>{`${((+prod.pau) * (+prod.quantite))?.toLocaleString()} GNF`}</td>
                                        <td className="bg-success-subtle">{`${prod?.pvu?.toLocaleString()} GNF`}</td>
                                        <td>{`${((+prod.pvu) * (+prod.quantite))?.toLocaleString()} GNF`}</td>
                                        <td>{prod.vendu}</td>
                                        <td>{+prod.quantite - (+prod.vendu)}</td>

                                    </tr>
                                ))
                            }
                            <tr className="align-middle">
                                <td colSpan={3} className="text-center fw-bold fs-3">Totaux</td>
                                <td className="fw-bold">{total_qte}</td>
                                <td className="fw-bold">{(total_pau)?.toLocaleString()} GNF</td>
                                <td className="fw-bold"></td>
                                <td className="fw-bold">{(total_pvu)?.toLocaleString()} GNF</td>
                                <td className="fw-bold">{total_qte_vendu}</td>
                                <td className="fw-bold">{total_qte - total_qte_vendu}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    );
}

export default DetailAppro;