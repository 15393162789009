import { useRef, useState } from "react";
import SearchField from "../components/SeachField";
import { decryptData, defaulUrl, impression, } from "../components/const";


const RapportSalaire = () => {
    const currentDate = new Date();
    const [annee, setAnnee] = useState(currentDate.getFullYear())
    const [mois, setMois] = useState(currentDate.getMonth() + 1 < 10 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth() + 1)
    const [isLoading, setIsLoading] = useState(false)
    const [lesPayes, setLesPayes] = useState(null);
    const handleShowRapport = () => {
        let status = 0;
        setIsLoading(true)
        fetch(`${defaulUrl}salaire/${mois}/${annee}`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            }
        }).then((response) => {
            status = response.status;
            return response.json()
        }).then(data => {
            setIsLoading(false);
            if (status === 200) {
                console.log(data)
                setLesPayes(data)
            }
        })
    }
    const toPrint = useRef()
    const printbulletin = (pers) => {
        let status = 0;
        setIsLoading(true)
        fetch(`${defaulUrl}bulletin/${pers.id}`, {
            method: "GET",
            headers: {
                Accept: 'application/pdf',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            },
            responseType: 'blob'
        }).then((response) => {
            status = response.status;
            return response.blob()
        }).then(data => {
            setIsLoading(false);
            if (status === 200) {
                const url = window.URL.createObjectURL(data)
                const link = document.createElement('a')
                link.href = url
                link.download = `bulletin_${pers.nom}_${pers.phone}_${pers.mois}_${pers.annee}.pdf`
                document.body.appendChild(link)
                link.click()
                window.URL.revokeObjectURL(url)
            }
        })
    }

    return (
        <div className="rappor mt-2">
            <h4 className="text-primary">RAPPORT DE PAIEMENT DE SALAIRE MENSUEL</h4>
            <div className="row my-2 align-items-center justify-content-between">
                <div className="col-lg-6 row">
                    <div className="col-md-4 pe-1">
                        <div className="form-group">
                            <label htmlFor="" className="form-label">Années</label>
                            <select name="" id="" className="form-select"
                                defaultValue={annee} onChange={(e) => setAnnee(e.target.value)}>
                                <option value="">Choisir une année</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4 text-start m-0 p-0 ">
                        <div className="form-group">
                            <label htmlFor="" className="form-label">Mois</label>
                            <div className="input-group">
                                <select name="" id="" className="form-select"
                                    defaultValue={mois} onChange={(e) => setMois(e.target.value)}>
                                    <option value="">Choisir un mois</option>
                                    {<option value="01">Janvier</option>}
                                    {<option value="02">Février</option>}
                                    {<option value="03">Mars</option>}
                                    {<option value="04">Avril</option>}
                                    {<option value="05">Mai</option>}
                                    {<option value="06">Juin</option>}
                                    {<option value="07">Juillet</option>}
                                    {<option value="08">Août</option>}
                                    {<option value="09">Septembre</option>}
                                    {<option value="10">Octobre</option>}
                                    {<option value="11">Novembre</option>}
                                    {<option value="12">Décembre</option>}
                                </select>
                                <button className="btn btn-primary" onClick={handleShowRapport}>
                                    <i className="bx bx-show"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {lesPayes?.length > 0 &&
                    <div className="col-lg-4">
                        <SearchField placeholder='Rechercher un travailleur' plage='liste' conteneur='col-md-3' critere='name' />
                    </div>
                }
            </div>
            <div className="text-end">
                <button className="btn btn-primary w-auto" onClick={() => impression(toPrint.current, "landscape", `Etat de paie du ${mois}/${annee}`)}>
                    <i className="bx bx-printer"></i></button></div>
            {isLoading && <div className="loader"></div>}
            {
                lesPayes?.length > 0 ?
                    <div className="table-responsive mt-2" ref={toPrint}>
                        <table className="table table-striped table-hover table-bordered">
                            <thead className="table-dark">
                                <tr className="align-middle">
                                    <th>N°</th>
                                    <th>PRENOMS & NOM</th>
                                    <th>TELEPHONE</th>
                                    <th>SALAIRE</th>
                                    <th>PRIME</th>
                                    <th>DETTE AVANT PAIE</th>
                                    <th>DETTE REMOBOURSE</th>
                                    <th>RECU</th>
                                    <th>COMMENTAIRE</th>
                                    <th>DATE</th>
                                    <th className="text-end">ACTIONS</th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    lesPayes?.map((pers, ind) => (
                                        <tr className="align-middle" key={ind}>
                                            <td>{ind + 1}</td>
                                            <td>{pers.nom?.toUpperCase()}</td>
                                            <td>{pers.phone}</td>
                                            <td>{pers.salaire?.toLocaleString()} </td>
                                            <td>{pers.prime?.toLocaleString()} </td>
                                            <td>{pers.dette?.toLocaleString()} </td>
                                            <td>{pers.remboursement?.toLocaleString()} </td>
                                            <td>{pers.salaire_net?.toLocaleString()} </td>
                                            <td>{pers.commentaire}</td>
                                            <td>{pers.date}</td>
                                            <td className="text-end">
                                                <button className="btn btn-danger" title="imprimer le bulletin" onClick={() => printbulletin(pers)}><i className="bx bx-download"></i></button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>


                    </div> :
                    <div className="bg-danger-subtle text-danger text-center fs-4 p-3">Aucun travailleur n'a été payé pour le mois choisis!</div>
            }
        </div>
    )
}

export default RapportSalaire;