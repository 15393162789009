import { useRef, useState } from "react";
import { decryptData, defaulUrl, Loading } from "../components/const";
import Swal from "sweetalert2";

const ModalEditeAgent = ({ agent ,url}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [phone, setPhone] = useState(null)
    const [prenom, setPrenom] = useState(null);
    const [nom, setNom] = useState(null);
    const [adresse, setAdresse] = useState(null);
    const [errors, setErrors] = useState(null);
    const ref = useRef();


    const handleSubmite = (e) => {
        e.preventDefault();
        setIsLoading(true)
        const formdata = new FormData(e.target);
        let status = 0;
        fetch(`${defaulUrl}${url}/${agent.id}/edite`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                ContentType: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            },
            body: formdata
        }).then((response) => {
            status = response.status;
            return response.json()
        }).then(data => {
            setIsLoading(false);
            if (status == 200) {
                Swal.fire({
                    title: 'Succès',
                    text: data.message,
                    icon: 'success'
                }).then(() => {

                    ref.current.click();
                })
            } else {
                setErrors(data.errors);
            }
        })
        setErrors(null);


    }


    return (
        <div className="modal fade " id="editeAgent" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header bg-secondary-subtle">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Modifier un agent</h1>
                        <button type="button " className="btn-close btn-danger" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form onSubmit={(e) => handleSubmite(e)}>
                        <div className="modal-body position-relative">
                            <div className="form-group">
                                <label htmlFor="" className="fs-5">Prénoms</label>
                                <input type="text" className="form-control" name="prenom" defaultValue={agent?.prenom} required />
                                {errors?.prenom && <span className="text-danger mt-2">{errors?.prenom}</span>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="" className="fs-5">Nom</label>
                                <input type="text" className="form-control" name="nom" defaultValue={agent?.nom} required />
                                {errors?.nom && <span className="text-danger mt-2">{errors?.nom}</span>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="" className="fs-5">Téléphone</label>
                                <input type="number" className="form-control hide-arrow" name="telephone" defaultValue={agent?.telephone} required />
                                {errors?.telephone && <span className="text-danger mt-2">{errors?.telephone}</span>}
                            </div>

                            <div className="form-group">
                                <label htmlFor="" className="fs-5">Adresse</label>
                                <input type="text" className="form-control" name="adresse" defaultValue={agent?.adresse} required />
                                {errors?.adresse && <span className="text-danger mt-2">{errors?.adresse}</span>}
                            </div>
                            {isLoading && <Loading />}
                        </div>
                        <div className="modal-footer">
                            <a className="btn btn-danger" data-bs-dismiss="modal" ref={ref}>Annuler</a>
                            <button type="submite" className="btn btn-success" disabled={isLoading}>Enregistrer</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>);
}

export default ModalEditeAgent;