import Swal from "sweetalert2";
import { decryptData, defaulUrl } from "../components/const";
import UseFetch from "../components/UseFetch";

const Suppression = () => {
    const { data: factures, isloading } = UseFetch(`suppression`);
    const user = decryptData("USER");
    const handleDelete = (id, statut, operation, message) => {
        Swal.fire({
            title: "Confirmation",
            text: `Souhaitez-vous ${message} cette suppression`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'OUI',
            cancelButtonText: 'Annuler',
            cancelButtonColor: 'red',
            confirmButtonColor: 'green'
        }).then(confirm => {
            if (confirm.isConfirmed) {
                let status = 0;
                fetch(operation === 'update' ? `${defaulUrl}facture/${id}/delete-request` :
                    `${defaulUrl}factures/delete/${id}`
                    , {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
                        },
                        body: JSON.stringify({ suppression: statut })
                    }).then(response => {
                        status = response.status;
                        return response.json();
                    }).then(data => {
                        if (status === 200) {
                            Swal.fire({
                                title: "Success",
                                text: data.message,
                                icon: 'success',
                            })
                        }
                    })


            }
        })
    }
    const profile = decryptData("user_profile");
    console.log(profile)
    return (
        isloading ? <div className="loader"></div> :
            <div className="suppression">
                <h4>SUPPRESSION DE FACTURE</h4>
                {
                    factures?.length > 0 ? <div className="table-responsive  fill-content">
                        <table className="table  table-hover table-striped table-bordered">
                            <thead className="table-primary text-center">
                                <tr className="align-middle">
                                    <th>N°FACTURE</th>
                                    <th>MONTANT</th>
                                    <th>STATUS</th>
                                    <th>MAGASIN</th>
                                    <th>DATE</th>
                                    <th className="text-end">ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody className="plage">
                                {profile ?
                                    factures?.map((facture, ind) => (
                                        (facture.idmagasin === profile.idmagasin)
                                        && <tr key={ind} className="align-middle">
                                            <td>{facture.numero}</td>
                                            <td>{facture.montant.toLocaleString()}</td>
                                            <td>{facture.suppression}</td>
                                            <td>{facture.magasin}</td>
                                            <td>{facture.date}</td>
                                            <td className="text-end">
                                                {
                                                    (user.droit === 'Magasin') ? <>
                                                        <button className="btn btn-primary m-1" title="Annuler" onClick={() => handleDelete(facture.id, null, "update", "annuler")}>
                                                            <i className="bx bx-x"></i></button>
                                                        {facture.suppression === 'Demande acceptée' && <button className="btn btn-danger m-1" title="Supprimer" onClick={() => handleDelete(facture.id, null, "delete", "valider")}>
                                                            <i className="bx bx-trash"></i></button>}
                                                    </> :
                                                        <>
                                                            {facture?.suppression != "Demande acceptée" && <button className="btn btn-success m-1" title="Confirmer" onClick={() => handleDelete(facture.id, "Demande acceptée", "update", "accepter")}><i className="bx bx-check-circle"></i></button>}

                                                            <button className="btn btn-danger m-1" title="Rejeter" onClick={() => handleDelete(facture.id, "Demande rejetée", "update", "rejeter")}>
                                                                <i className="bx bx-x-circle"></i></button>
                                                        </>
                                                }
                                            </td>
                                        </tr>
                                    ))
                                    : factures?.map((facture, ind) => (
                                        <tr key={ind} className="align-middle">
                                            <td>{facture.numero}</td>
                                            <td>{facture.montant.toLocaleString()}</td>
                                            <td>{facture.suppression}</td>
                                            <td>{facture.magasin}</td>
                                            <td>{facture.date}</td>
                                            <td className="text-end">
                                                {
                                                    (user.droit === 'Magasin') ? <>
                                                        <button className="btn btn-primary m-1" title="Annuler" onClick={() => handleDelete(facture.id, null, "update", "annuler")}>
                                                            <i className="bx bx-x"></i></button>
                                                        {facture.suppression === 'Demande acceptée' && <button className="btn btn-danger m-1" title="Supprimer" onClick={() => handleDelete(facture.id, null, "delete", "valider")}>
                                                            <i className="bx bx-trash"></i></button>}
                                                    </> :
                                                        <>
                                                            {facture?.suppression != "Demande acceptée" && <button className="btn btn-success m-1" title="Confirmer" onClick={() => handleDelete(facture.id, "Demande acceptée", "update", "accepter")}><i className="bx bx-check-circle"></i></button>}

                                                            <button className="btn btn-danger m-1" title="Rejeter" onClick={() => handleDelete(facture.id, "Demande rejetée", "update", "rejeter")}>
                                                                <i className="bx bx-x-circle"></i></button>
                                                        </>
                                                }
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                        : <div className="row justify-content-center p-2 bg-danger-subtle text-danger">Aucune demande de suppression</div>
                }

            </div>
    );
}

export default Suppression;