import { createBrowserRouter } from "react-router-dom";
import DefaultLayout from "./Administration/DefaultLayout";
import Products from "./Produits/Products";
import Categories from "./Produits/Categories";
import AddProduct from "./Produits/AddProduct";
import EditeProduct from "./Produits/EditeProduct";
import Dashboard from "./Administration/Dashboard";
import AddMagasin from "./Magasins/AddMagasin";
import Magasins from "./Magasins/Magasins";
import Contenaires from './Contenaires/Contenaires'
import AddApprovision from "./Approvision/AddApprovision";
import Approvisions from "./Approvision/Approvisions";
import Users from "./Authentification/Users";
import AddUser from "./Authentification/AddUser";
import Profile from "./Authentification/Profile";
import Login from "./Authentification/Login"
import GuestLayout from "./Administration/GuestLayout";
import GetPwd from './Authentification/GetPwd'
import Pays from "./Settings/Pays";
import Ville from "./Settings/Ville";
import Commune from "./Settings/Commune";
import Quartier from "./Settings/Quartier";
import ListeProduit from "./Produits/ListeProduits";
import SelectedProducts from "./Produits/SelectedProducts";
import Stocks from "./Produits/Stocks";
import StokMois from "./Produits/StockMois";
import Fournisseurs from "./Fournisseurs/Fournisseurs";
import Solde from "./Fournisseurs/Solde";
import FacturesImpayes from "./Fournisseurs/FacturesImpayes";
import ListeProduitParMagasin from "./Magasins/ListeProduitParMagasin";
import StockMagasin from "./Produits/StocksMagasins";
import Facturation from "./Ventes/Facturation";
import Addconteneur from "./Contenaires/AddContenaire";
import Aide from "./Settings/Aide";
import DetailAppro from "./Approvision/DetailAppro";
import VenteJour from "./Ventes/VenteJour";
import VenteParConteneur from "./Ventes/venteParConteneur";
import ListeVenteAdmin from "./Ventes/ListeVenteAdmin";
import Clients from "./Clients/Clients";
import SoldeClient from "./Clients/SoldeClient";
import FactureNonPayees from "./Clients/FacturesNonPayees";
import ModalFacturesClient from "./Clients/ModalFacturesClient";
import PdfConverter from "./Ventes/PdfConverter";
import RapportComptable from "./Comptability/RapportComptable";
import Recouvrements from "./Comptability/Recouvrement";
import RapportAdmin from "./Administration/RapportAdmin";
import RapportAdminComptable from "./Administration/RapportAdminComptable";
import Benefice from "./Administration/Benefice";
import ListeVersement from "./Magasins/ListeVersements";
import DetteInitiale from "./Clients/DetteInitiale";
import Depenses from "./Comptability/DepenseConteneur";
import Printer from "./Administration/Printer";
import DetailPaiement from "./Clients/DetailPaiement";
import DetailFactures from "./Clients/DetailFacture";
import CompteClient from "./Clients/CompteClient";
import AllClients from "./Administration/AllClients";
import ModalRemboursement from "./Fournisseurs/ModalRemboursement";
import EditeFacture from "./Ventes/EditeFacture";
import Suppression from "./Ventes/SuppressionFacture";
import Caisse from "./Comptability/Caisse";
import Agents from "./Personnel/Agents";
import ConfirmConteneur from "./Contenaires/ConfirmConteneur";
import Transitaires from "./Personnel/Transitaires";
import CompteTransitaire from "./Personnel/CompteTransitaire";
import CompteFournisseur from "./Fournisseurs/CompteFournisseur";
import Personnel from "./Personnel/Personnel";
import ListePers from "./Personnel/ListePers";
import Pointage from "./Personnel/Pointage";
import RapportPresence from "./Personnel/RapportPresence";
import ClassementVendeur from "./Personnel/ClassemenetVendeurs";
import PaiementSalaire from "./Personnel/PaiementSalaire";
import RapportSalaire from "./Personnel/RapportSalaire";
import Messagerie from "./Administration/Messagerie";
const router = createBrowserRouter([
    {
        path: '/',
        element: <DefaultLayout />,
        children: [
            {
                path: '/',
                element: <Dashboard />
            },
            {
                path: '/home',
                element: <Dashboard />
            },
            // Produits
            {
                path: '/products',
                element: <Products />
            },
            {
                path: '/products/categories',
                element: <Categories />
            },
            {
                path: '/products/add',
                element: <AddProduct />
            },
            {
                path: '/products/:id',
                element: <EditeProduct />
            },
            {
                path: '/products/stock-actuel',
                element: <Stocks />
            },
            {
                path: '/products/stock-du-mois',
                element: <StokMois />
            },

            // Magasin
            {
                path: '/magasins/add',
                element: <AddMagasin />
            },


            {
                path: '/magasins/liste',
                element: <Magasins />
            },

            // Contenaires



            {
                path: '/conteneurs/add',
                element: <Addconteneur />
            },
            {
                path: '/conteneurs/add-products',
                element: <ListeProduit />
            },
            {
                path: '/conteneurs/add-prix',
                element: <SelectedProducts />
            },
            {
                path: '/conteneurs',
                element: <Contenaires />,

            },
            {
                path: '/depenses',
                element: <Depenses />,

            },
            // Approvisionnement
            {
                path: '/approvisions/add',
                element: <AddApprovision />
            },
            {
                path: '/approvisions',
                element: <Approvisions />
            },
            {
                path: '/approvisions/:id',
                element: <DetailAppro />
            },
            {
                path: '/confirmation',
                element: <ConfirmConteneur />
            },
            // Pays, villes, communes et quartiers
            {
                path: '/parametres/aide',
                element: <Aide />
            },
            {
                path: '/parametres/pays',
                element: <Pays />
            },
            {
                path: '/parametres/villes',
                element: <Ville />
            },
            {
                path: '/parametres/communes',
                element: <Commune />
            },
            {
                path: '/parametres/quartiers',
                element: <Quartier />
            },
            // ROUTES EN FONCTION DES MAGASINS 
            {
                path: '/magasins/stocks',
                element: <ListeProduitParMagasin />//lISTE DES PRODUITS PAR MAGASIN(compte magasin)
            },
            {
                path: '/magasins/:id/stocks',
                element: <StockMagasin />//lISTE DES PRODUITS PAR MAGASIN(compte admin)
            },

            // GESTION DES VENTES

            {
                path: '/facturation',
                element: <Facturation />
            },
            {
                path: '/paiements',
                element: <DetailPaiement />
            },
            {
                path: '/print/:idfacture',
                element: <PdfConverter />
            },

            {
                path: '/vente-du-jour',
                element: <VenteJour />
            },
            {
                path: '/vente-conteneur',
                element: <VenteParConteneur />
            },
            {
                path: '/ventes/mag/:id',
                element: <ListeVenteAdmin />
            },
            {
                path: '/ventes/factures',
                element: <FactureNonPayees />
            },
            {
                path: '/facture/:id/detail',
                element: <DetailFactures />
            },
            {
                path: '/facture/paiement',
                element: <ModalRemboursement table={'factures'} />
            },
            {
                path: '/factures/suppression',
                element: <Suppression />
            },
            {
                path: '/facture/editer',
                element: <EditeFacture />
            },
            {
                path: 'mag/:idmag/client/:id/factures',
                element: <ModalFacturesClient />
            },
            {
                path: '/recouvrements',
                element: <Recouvrements />
            },
            {
                path: '/versements',
                element: <ListeVersement />
            },
            {
                path: '/rapport-magasins',
                element: <RapportAdmin />
            },
            {
                path: '/rapport-comptable',
                element: <RapportAdminComptable />
            },
            {
                path: '/admin/benefice',
                element: <Benefice />
            },
            {
                path: '/messagerie',
                element: <Messagerie />
            },
            // GERE LA CAISSE DE VENTE ET CELLE DE LA COMPTABILITE
            {
                path: '/caisse',
                element: <Caisse />
            },

            // clients
            {
                path: '/clients',
                element: <Clients />
            },
            {
                path: '/clients-all',
                element: <AllClients />
            },
            {
                path: '/clients/solde',
                element: <SoldeClient />
            },
            {
                path: '/clients/dette',
                element: <DetteInitiale />
            },
            {
                path: '/client/compte',
                element: <CompteClient />
            },

            // Fournisseurs
            {
                path: '/fournisseurs',
                element: <Fournisseurs />
            },
            {
                path: '/fournisseurs/solde',
                element: <Solde />
            },
            {
                path: '/fournisseurs/factures',
                element: <FacturesImpayes />
            },
            {
                path: '/compte-fournisseur',
                element: <CompteFournisseur />
            },
            {
                path: '/comptability-rapport',
                element: <RapportComptable />
            },
            // Personnel
            {
                path: '/personnel',
                element: <Personnel />,
                children: [
                    {
                        path: '/personnel/liste',
                        element: <ListePers />
                    },
                    {
                        path: '/personnel/pointage',
                        element: <Pointage />
                    },
                    {
                        path: '/personnel/rapport-presence',
                        element: <RapportPresence />
                    },
                    {
                        path: '/personnel/classement',
                        element: <ClassementVendeur />
                    },
                    {
                        path: '/personnel/salaire',
                        element: <PaiementSalaire />
                    },
                    {
                        path: '/personnel/rapport',
                        element: <RapportSalaire />
                    },
                ]
            },
            {
                path: '/agents',
                element: <Agents />
            },
            {
                path: '/transitaires',
                element: <Transitaires />
            },
            {
                path: '/transitaire',
                element: <CompteTransitaire />
            },


            // Users
            {
                path: '/users',
                element: <Users />
            },
            {
                path: '/users/add',
                element: <AddUser />
            },
            {
                path: '/users/profile',
                element: <Profile />
            },

            {
                path: '/printer',
                element: <Printer />

            }


        ]
    },

    {
        path: '/',
        element: <GuestLayout />,
        children: [
            {
                path: '/login',
                element: <Login />
            },
            {
                path: '/password',
                element: <GetPwd />
            },
        ]
    }


]);

export default router;