import { useEffect, useRef, useState } from "react";
import { decryptData, defaulUrl, impression } from "../components/const";

const ClassementVendeur = () => {
    const [datejour, setDateJour] = useState((new Date()).toISOString().slice(0, 10));
    const toPrint = useRef();
    const [date1, setDate1] = useState(datejour);
    const [date2, setDate2] = useState(datejour);
    const [isLoading, setIsLoading] = useState(false);
    const [vendeurs, setVendeurs] = useState([]);
    const handleShowByPeriode = () => {
        let status = 0;
        setIsLoading(true)
        fetch(`${defaulUrl}vendeurs/classement/${date1}/${date2}`, {
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + decryptData('ACCESS_TOKEN'),
                Accept: 'application/json',
                "Content-Type": "application/json"
            },
        }).then((response) => {
            status = response.status;
            return response.json();
        }).then(data => {
            setIsLoading(false);
            if (status === 200) {
                data?.sort((a, b) => b.montant - a.montant);
                setVendeurs(data)
            }
        })
    }
    useEffect(() => {
        handleShowByPeriode()
    }, [])
    return (
        <div className="classement">
            <div className="row py-2">
                <div className="modal-footer justify-content-start text-primary">
                    <h6 className="">CLASSEMENT DES VENDEURS DU </h6>

                    <input type="date" className="form-control w-auto m-1" id="date1" name="date1" defaultValue={date1} onChange={(e) => setDate1(e.target.value)} /> AU
                    <input type="date" className="form-control w-auto m-1" id="date2" name="date2" defaultValue={date2} onChange={(e) => setDate2(e.target.value)} />

                    <button type="submit" className="btn btn-primary m-1" onClick={() => handleShowByPeriode()}><i className="bx bx-show" ></i></button>
                    <button className="btn btn-primary w-auto" onClick={() => impression(toPrint.current, "landscape", "CLASSEMENT DES VENDEURS PAR ORDRE DE PRODUCTIVITE")}>
                        <i className="bx bx-printer"></i>
                    </button>
                </div>
            </div>

            {
                isLoading ? <div className="loader"></div> :
                    <div className="table-responsive mt-2" ref={toPrint}>
                        <table className="table table-striped table-hover table-bordered" >
                            <thead className="table-dark">
                                <tr>
                                    <th>N°</th>
                                    <th>PRENOMS ET NOM</th>
                                    <th>MAGASIN</th>
                                    <th>MONTANT VENTE</th>
                                    <th>MONTANT PAYE</th>
                                    <th>MONTANT IMPAYE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    vendeurs?.map((vend, ind) => (
                                        <tr key={ind}>
                                            <td>{ind + 1}</td>
                                            <td>{vend.vendeur?.toUpperCase()}</td>
                                            <td>{vend.magasin?.toUpperCase()}</td>
                                            <td>{vend.montant?.toLocaleString()} GNF</td>
                                            <td>{vend.paye?.toLocaleString()} GNF</td>
                                            <td>{vend.reste?.toLocaleString()} GNF</td>
                                        </tr>
                                    ))

                                }
                            </tbody>
                        </table>

                    </div>
            }
        </div>
    );
}

export default ClassementVendeur;