import { useRef, useState } from "react";
import { decryptData, defaulUrl, formatMoney, Loading, onfocus } from "../components/const";
import Swal from "sweetalert2";

const ModalPaieSalaire = ({ travailleur }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [montant, setMontant] = useState(0)
    const ref = useRef()
    const currentDate = new Date();
    const [annee, setAnnee] = useState(currentDate.getFullYear())
    const [mois, setMois] = useState(currentDate.getMonth() + 1 < 10 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth() + 1)
    const [prime, setPrime] = useState(0)
    const handleSubmite = (e) => {
        e.preventDefault();
        setIsLoading(true)
        const formdata = new FormData(e.target);
        formdata.append('iduser', +travailleur?.id);
        formdata.append('salaire', +travailleur?.salaire);
        formdata.append('dette', +travailleur?.dette);
        formdata.append('prime', prime);
        formdata.append('net', (+travailleur?.salaire) + prime - (+travailleur?.dette));
        let status = 0;
        fetch(`${defaulUrl}personnel/payerSalaire`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                ContentType: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            },

            body: formdata
        }).then((response) => {
            status = response.status;
            return response.json()
        }).then(data => {
            setIsLoading(false);
            console.table(data);
            if (status == 200) {
                Swal.fire({
                    title: 'Succès',
                    text: `${data.message}\nSouhaitez-vous imprimer le bon?`,
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: "OUI",
                    cancelButtonText: "NON"
                }).then((confirm) => {
                    if (confirm.isConfirmed) {

                        printbulletin(data.id)
                    }
                })
            } else {
                Swal.fire({
                    title: 'Erreur',
                    text: data.message,
                    icon: 'error'
                })
                return;
            }
        })
        setErrors(null);
    }

    const printbulletin = (id) => {
        let status = 0;
        setIsLoading(true)
        fetch(`${defaulUrl}bulletin/${id}`, {
            method: "GET",
            headers: {
                Accept: 'application/pdf',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            },
            responseType: 'blob'
        }).then((response) => {
            status = response.status;
            return response.blob()
        }).then(data => {
            setIsLoading(false);
            if (status === 200) {
                const url = window.URL.createObjectURL(data)
                const link = document.createElement('a')
                link.href = url
                link.download = `bulletin_${travailleur.nom}_${travailleur.phone}_${travailleur.mois}_${travailleur.annee}.pdf`
                document.body.appendChild(link)
                link.click()
                window.URL.revokeObjectURL(url)
            }
        })
    }

    return (
        <div className="modal fade " id="modal-salaire" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header bg-secondary-subtle">
                        <h1 className="modal-title fs-5" >Paiement de salaire</h1>
                        <button type="button " className="btn-close btn-danger" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form onSubmit={(e) => handleSubmite(e)} >
                        <div className="modal-body position-relative">
                            <div className="form-group">
                                <label htmlFor="" className="fs-5">Nom complet</label>
                                <input type="text" className="form-control"
                                    value={(`${travailleur?.firstname} ${travailleur?.lastname}`)} disabled />
                            </div>
                            <div className="row py-1">
                                <div className="form-group col-lg-6">
                                    <label htmlFor="salaire" className="fs-5">Salaire</label>
                                    <div className="input-group">
                                        <input type="text" name="salaire" className="form-control" defaultValue={(travailleur?.salaire)?.toLocaleString()} disabled />
                                        <span className="input-group-text" id="basic-addon2">GNF</span>
                                    </div>
                                </div>
                                <div className="form-group col-lg-6">
                                    <label htmlFor="" className="fs-5">Dette</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" name="dette" defaultValue={(travailleur?.dette)?.toLocaleString()} disabled />
                                        <span className="input-group-text" id="basic-addon2">GNF</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row pb-1">
                                <div className="col-lg-6 form-group">
                                    <label htmlFor="" className="fs-5">Mois</label>
                                    <select name="mois" id="mois" className="form-select"
                                        defaultValue={mois} onChange={(e) => setMois(e.target.value)}>
                                        <option value="">Choisir un mois</option>
                                        {<option value="01">Janvier</option>}
                                        {<option value="02">Février</option>}
                                        {<option value="03">Mars</option>}
                                        {<option value="04">Avril</option>}
                                        {<option value="05">Mai</option>}
                                        {<option value="06">Juin</option>}
                                        {<option value="07">Juillet</option>}
                                        {<option value="08">Août</option>}
                                        {<option value="09">Septembre</option>}
                                        {<option value="10">Octobre</option>}
                                        {<option value="11">Novembre</option>}
                                        {<option value="12">Décembre</option>}
                                    </select>
                                </div>
                                <div className="col-lg-6 form-group">
                                    <label htmlFor="" className="fs-5">Annee</label>
                                    <select name="annee" id="annee" className="form-select"
                                        defaultValue={annee} onChange={(e) => setAnnee(e.target.value)}>
                                        <option value="">Choisir un mois</option>
                                        {<option value="2024">2024</option>}
                                        {<option value="2025">2025</option>}
                                    </select>
                                </div>
                                {isLoading && <span className="position-absolute"> <Loading /></span>}
                            </div>
                            <div className="row py-1">
                                <div className="form-group col-lg-6">
                                    <label htmlFor="" className="fs-5">Prime</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" defaultValue={(prime)?.toLocaleString()} onChange={(e) => setPrime(+ (e.target.value.replace(/\D/g, "")))}
                                            onBlur={(e) => formatMoney(e)} onFocus={(e) => onfocus(e)} />
                                        <span className="input-group-text" id="basic-addon2">GNF</span>
                                    </div>
                                </div>
                                <div className="form-group col-lg-6">
                                    <label htmlFor="" className="fs-5">Net à payer</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control"
                                            value={((travailleur?.salaire + prime) - travailleur?.dette)?.toLocaleString()} disabled />
                                        <span className="input-group-text" id="basic-addon2">GNF</span>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" ref={ref}>Annuler</button>
                                <button type="submite" className="btn btn-success" disabled={isLoading} >Valider</button>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    );
}

export default ModalPaieSalaire;