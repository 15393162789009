import { useEffect, useRef, useState } from "react";
import { decryptData, defaulUrl, impression } from "../components/const";
const RapportPresence = () => {
    const currentDate = new Date();
    const [annee, setAnnee] = useState(currentDate.getFullYear())
    const [mois, setMois] = useState(currentDate.getMonth() + 1 < 10 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth() + 1)
    const toPrint = useRef();
    const [numberOfDays, setNumberOfDays] = useState(null);
    const [rapport, setRapport] = useState([]);
    const [load, setLoad] = useState(false)
    const handleShowRapport = () => {
        let status = 0;
        setLoad(true)
        fetch(`${defaulUrl}personnel/rapport-present/${annee}/${mois}`, {
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + decryptData('ACCESS_TOKEN'),
                Accept: 'application/json',
                "Content-Type": "application/json"
            },
        }).then((response) => {
            status = response.status;
            return response.json();
        }).then(data => {
            setLoad(false);
            if (status === 200) {
                data?.personnel?.sort((a, b) => b.nb_presence - a.nb_presence)
                setRapport(data?.personnel);
                setNumberOfDays(data?.nbjours)
            }
        })
    }

    useEffect(() => {
        handleShowRapport()
    }, [])
    return (
        <div className="rapport">
            {load && <div className="loader"></div>}
            <div className="row my-2 align-items-center">
                <div className="col-md-2 pe-1">
                    <div className="form-group">
                        <label htmlFor="" className="form-label">Années</label>
                        <select name="" id="" className="form-select"
                            defaultValue={annee} onChange={(e) => setAnnee(e.target.value)}>
                            <option value="">Choisir une année</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-2 text-start m-0 p-0">
                    <div className="form-group">
                        <label htmlFor="" className="form-label">Mois</label>
                        <div className="input-group">
                            <select name="" id="" className="form-select"
                                defaultValue={mois} onChange={(e) => setMois(e.target.value)}>
                                <option value="">Choisir un mois</option>
                                {<option value="01">Janvier</option>}
                                {<option value="02">Février</option>}
                                {<option value="03">Mars</option>}
                                {<option value="04">Avril</option>}
                                {<option value="05">Mai</option>}
                                {<option value="06">Juin</option>}
                                {<option value="07">Juillet</option>}
                                {<option value="08">Août</option>}
                                {<option value="09">Septembre</option>}
                                {<option value="10">Octobre</option>}
                                {<option value="11">Novembre</option>}
                                {<option value="12">Décembre</option>}
                            </select>
                            <button className="btn btn-primary" onClick={handleShowRapport}><i className="bx bx-show"></i></button>
                        </div>
                    </div>
                </div>
            </div>

            {
                load ? <div className="loader"></div> :
                    <>
                        <div className="text-end">
                            <button className="btn btn-primary w-auto" onClick={()=>impression(toPrint.current, "landscape", "")}>
                                <i className="bx bx-printer"></i>
                            </button>
                        </div>
                        <div className="table-responsive mt-3 small" ref={toPrint}>
                            <div className="h6 text-primary">LISTE DE PRESENCE PAR ORDRE D'ASSIDUITE</div>
                            <table className="table table-striped table-bordered">
                                <thead className="table-primary">
                                    <tr className="align-middle text-center">
                                        <th>PRENOMS & NOM</th>
                                        {
                                            Array.from({ length: numberOfDays }, (_, index) => <th key={index} width="1%">{index + 1}</th>)
                                        }
                                        <th>T. PRE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rapport?.map((pers, ind) => (
                                        <tr className="align-middle" key={ind}>
                                            <td>{(pers?.nom?.toUpperCase() ?? "Pas de nom")}</td>
                                            {
                                                pers?.presences?.map((p, i) => (
                                                    <td key={i} className={p ? p === 'Présent' ? 'bg-success-subtle' : 'bg-danger-subtle' : ''}>
                                                        {p?.substring(0, 3)}</td>
                                                ))
                                            }
                                            <td className="text-center">{pers?.nb_presence}</td>
                                        </tr>

                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
            }
        </div>


    );
}

export default RapportPresence;