
import CryptoJS from "crypto-js";
import Swal from "sweetalert2";
import html2pdf from 'html2pdf.js';
import '../css/style.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'

export const handleShowPwd = (e) => {
    e.preventDefault();
    let pwdInput = document.querySelectorAll('.show_hide_password input');
    let pwdIcon = document.querySelectorAll('.show_hide_password .bx');

    pwdInput.forEach(input => {
        if (input.getAttribute('type') === 'text') {
            input.setAttribute('type', 'password');
            pwdIcon.forEach(icon => {
                icon.classList.add('bx-hide');
                icon.classList.remove('bx-show');
            })
        } else {
            input.setAttribute('type', 'text');

            pwdIcon.forEach(icon => {
                icon.classList.remove('bx-hide');
                icon.classList.add('bx-show');
            })
        }
    })

}
// export const defaulUrl = 'http://127.0.0.1:8000/api/';
// export const photoUrl = 'http://127.0.0.1:8000/upload/';

export const defaulUrl = 'https://www.fasantextil.com/backend_magasin/public/api/';
export const photoUrl='https://www.fasantextil.com/backend_magasin/public/upload/';

// export const defaulUrl = 'https://www.fasantextil.com/demo/public/api/';
// export const photoUrl='https://www.fasantextil.com/demo/public/upload/';

export const Logout = (navigate, isLogedAuto = false) => {
    fetch(`${defaulUrl}logout`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`,
            Accept: 'Application/json'
        }
    }).then(response => {
        return response.json()
    }).then(() => {
        if (!isLogedAuto) {
            Swal.fire({
                title: "Déconnexion",
                text: 'Vous avez été déconnecté!',
                toast: true,
                timer: 2000,
                position: 'top-right',
                showConfirmButton: false,
            })
        }
        localStorage.clear();
        navigate()
    })
}

export const Loading = () => {
    return (
        <div className="Loading py-2 my-2">
            <div className="col-12 ">
                <div className="d-flex justify-content-center ">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden me-2">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export const token = localStorage.getItem('ACCESS_TOKEN');

export const saveFournisseur = (nom, prenoms, telephone, adresse) => {
    localStorage.removeItem('Fournisseur');
    localStorage.setItem('Fournisseur', JSON.stringify({
        'prenoms': prenoms,
        'nom': nom,
        'telephone': +telephone,
        'adresse': adresse
    }))
}
export const getFournisseur = JSON.parse(localStorage.getItem('Fournisseur'));

export const deleteLocalStorage = () => {
    localStorage.removeItem("Fournisseur");
    localStorage.removeItem("storedInfo");
    localStorage.removeItem("selectedProducts");
}

const myencrypteKey = 'encrypion';
export const encryptData = (name, data) => {
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), myencrypteKey).toString();
    localStorage.setItem(name, encrypted);
}
export const decryptData = (name) => {
    const encrypted = localStorage.getItem(name);
    if (encrypted) {
        const decrypted = CryptoJS.AES.decrypt(encrypted, myencrypteKey).toString(CryptoJS.enc.Utf8);
        return JSON.parse(decrypted);
    }
    return null
}
export const handeOnHoverSideBar = () => {
    const sidebar = document.querySelector('.toggled');
    if (sidebar) {
        sidebar.classList.add('sidebar-hovered');
    }
}
export const handeLeaveSideBar = () => {
    const sidebar = document.querySelector('.toggled');
    if (sidebar) {
        sidebar.classList.remove('sidebar-hovered');
        const ul_list = document.querySelectorAll('ul.show');
        if (ul_list) {
            ul_list.forEach(elem => {
                elem.classList.remove('show');
            })
        }
    }
}

export const deleter = (url) => {

    let status = 0;
    Swal.fire({
        title: "Suppression de donnees",
        text: "Vous voulez vraiment supprimer cet element",
        icon: 'question',
        showCancelButton: "true",
    }).then(conf => {
        if (conf.isConfirmed) {
            fetch(`${defaulUrl}${url}`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`,
                    Accept: 'Application/json'
                }
            }).then(response => {
                status = response.status;
                return response.json();
            }).then(data => {
                if (status === 200) {
                    Swal.fire({
                        timer: 3000,
                        title: "Succes",
                        text: data.message,
                        icon: 'success'
                    }).then(() => {
                    })
                }
            })
        }
    })

}



export const formatMoney = (e) => {
    let text = e.target.value.toString();
    text = text.replace(/\D/g, "");
    text = text.replace(/(\d)(\d{0})$/, "$1 ");
    text = text.replace(/(?=(\d{3})+(\D))\B/g, " ");
    e.target.value = text;
    return e;

}
export const onfocus = (e) => {
    let text = e.target.value.toString();
    if (+e.target.value === 0) {
        text = '';
    }
    text = text.replace(/\D/g, "");
    e.target.value = text;
    return e;
}


/**
 * @param {string} title
 * @param {HTMLElement} element 
 * @param {string} orientation 
 */
export const impression = (element, orientation = 'portrait', title, tab = null, totalqte = 0,
    totalpau = 0,
    totalpvu = 0) => {
    const user = decryptData('USER');
    const entete = `<div class="row">
            <div class="row bg-primary-subtle rounded py-2 text-primary align-items-center">
               <div class="col-2">
                 <img src="../images/store.png" alt="image" class="w-100"/>
               </div>
                <div class='col-8 text-center h4'>FANSAN TEXTIL</div>
            </div>
                <h6 class="text-center mt-2">${title.toLocaleUpperCase()}</h6>
        </div>`
    let table = null;
    if (tab) {
        totalqte = (tab?.reduce((previous, current) => { return previous + current.quantite }, 0));
        totalpau = (tab?.reduce((previous, current) => { return previous + current.pau }, 0)) || (tab?.reduce((previous, current) => { return previous + current.prix }, 0));
        totalpvu = (tab?.reduce((previous, current) => { return previous + current.pvu }, 0));

        table = `<table class="table table-stripped table-bordered">
            <thead class='table-dark'>
                <th>N°</th>
                <th>Produit</th>
                <th>quantite</th>
                <th>PAU</th>
                <th class="text-end" >PVU(GNF) </th>
            </thead>
            <tbody>
           ${tab?.map((prod, ind) => (
            ` <tr key=${ind}> 
             <td>${ind + 1}</td>
             <td>${prod.produit?.toLocaleUpperCase()}</td>
             <td>${prod.quantite}</td>
             <td>${(+prod?.prix || +prod?.pau)?.toLocaleString()}</td>
             <td>${(+prod.pvu)?.toLocaleString()}</td>
            </tr>`
        ))}
        <tr>
            <td colSpan=${2} class="fw-bold text-center">Totaux</td>
            <td class="fw-bold">${totalqte}</td>
            <td class="fw-bold">${totalpau.toLocaleString()} GNF</td>
            <td class="fw-bold">${totalpvu?.toLocaleString()} GNF</td>
        </tr>
        </tbody>
           
        </table>`

    } else {
        table = element.innerHTML
    }
    const foot = `
           <div class="row px-4">
            <h6 class="text-end fw-bold mt-3 px-4">${(new Date()).toLocaleDateString()}</h6>
            <h6 class="text-end fw-bold mt-2 px-4">${user?.droit.toLocaleUpperCase()}</h6>
           </div>     
    `
    const imprimable = entete + table + foot;

    const options = {
        filename: 'my-document.pdf',
        margin: [.2, .2],
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
            unit: 'in',
            format: 'letter',
            orientation: orientation,
        },
    };
    html2pdf().set(options).from(imprimable).outputPdf()
        .get('pdf')
        .then(function (pdfObj) {
            window.open(pdfObj.output("bloburl"), "F")
        });

}
export const prinTable = (orientation = 'portrait', title, tab) => {
    const user = decryptData('USER');
    const entete = `<div class="row">
            <div class="row bg-primary-subtle rounded py-2 text-primary align-items-center">
               <div class="col-2">
                 <img src="../images/store.png" alt="image" class="w-100"/>
               </div>
                <div class='col-8 text-center h4'>FANSAN TEXTIL</div>
            </div>
                <h6 class="text-center mt-2">${title.toLocaleUpperCase()}</h6>
        </div>`
    const mytable = `<table class="table table-stripped table-bordered">
            <thead class='table-dark'>
                <th>N°</th>
                <th>PRODUITS</th>
                <th>DATE</th>
             </thead>
         <tbody>
        
           ${tab?.map((prod, ind) => (
        ` <tr key=${ind}> 
             <td>${ind + 1}</td>
             <td>${prod.produit?.toLocaleUpperCase()}</td>
             <td>${prod?.updated_at}</td>
            </tr>`
    ))}
        </tbody>
        </table>`

    const foot = `<div class="row px-4">
            <h6 class="text-end fw-bold mt-3 px-4">${(new Date()).toLocaleDateString()}</h6>
            <h6 class="text-end fw-bold mt-2 px-4">${user?.droit.toLocaleUpperCase()}</h6>
           </div>`
    const imprimable = entete + mytable + foot;
    const options = {
        filename: 'my-document.pdf',
        margin: [.5, .3],
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
            unit: 'in',
            format: 'letter',
            orientation: orientation,
        },
    };
    html2pdf().set(options).from(imprimable).outputPdf()
        .get('pdf')
        .then(function (pdfObj) {
            window.open(pdfObj.output("bloburl"), "F")
        });
}

