import { useRef, useState } from "react";
import { decryptData, defaulUrl, formatMoney, Loading, onfocus } from "../components/const";
import Swal from "sweetalert2";

const ModalEditeSalaire = ({ travailleur }) => {
    const [isLoading, setIsLoading] = useState(false);
    const ref = useRef()
    const [newsalaire, setNewSalaire] = useState(0)
    const handleSubmite = (e) => {
        e.preventDefault();
        if (!newsalaire) {
            return
        }
        setIsLoading(true)
        const formdata = new FormData(e.target);
        formdata.append('idtravailleur', travailleur?.id)
        let status = 0;
        fetch(`${defaulUrl}personnel/setSalaire`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                ContentType: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            },
            body: formdata
        }).then((response) => {
            status = response.status;
            return response.json()
        }).then(data => {
            setIsLoading(false);
            console.log(data);
            if (status == 200) {
                Swal.fire({
                    title: 'Succès',
                    text: data.message,
                    icon: 'success'
                }).then(() => {

                    ref.current.click();
                })
            }
        })
    }
    return (
        <div className="modal fade " id="modal-EditeSalaire" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header bg-secondary-subtle">
                        <h1 className="modal-title fs-5" >Modification de salaire</h1>
                        <button type="button " className="btn-close btn-danger" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form onSubmit={(e) => handleSubmite(e)} >
                        <div className="modal-body position-relative">
                            <div className="form-group">
                                <label htmlFor="" className="fs-5">Nom complet</label>
                                <input type="text" className="form-control"
                                    value={(`${travailleur?.firstname} ${travailleur?.lastname}`)} disabled />
                            </div>
                            <div className="row py-1">
                                <div className="form-group col-lg-6">
                                    <label htmlFor="salaire" className="fs-5">Salaire actuel</label>
                                    <div className="input-group">
                                        <input type="text" name="salaire" className="form-control" value={(travailleur?.salaire)?.toLocaleString()} disabled />
                                        <span className="input-group-text" id="basic-addon2">GNF</span>
                                    </div>
                                </div>
                                <div className="form-group col-lg-6" >
                                    <label htmlFor="" className="fs-5">Nouveau salaire</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" name="newsalaire"
                                            onChange={(e) => setNewSalaire(e.target.value.replace(/\D/g, ""))}
                                            required defaultValue={(newsalaire)?.toLocaleString()} onFocus={(e) => onfocus(e)} />
                                        <span className="input-group-text" id="basic-addon2">GNF</span>
                                    </div>
                                    {!newsalaire && <span className="text-danger ">Ce champ est obligatoire!</span>}
                                </div>
                                {isLoading && <div className="text-center p-2">
                                    <Loading />
                                </div>}
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" ref={ref}>Annuler</button>
                                <button type="submite" className="btn btn-success" disabled={isLoading} >Valider</button>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    );
}

export default ModalEditeSalaire;