
import { useState } from "react";
import { decryptData, photoUrl } from "../components/const";
import SearchField from "../components/SeachField";
import UseFetch from "../components/UseFetch";
import ModalPaieSalaire from "./ModalPaieSalaire";
import ModalAvanceSalaire from "./ModalAvance";
import ModalEditeSalaire from "./ModalEditeSalaire";
import ModalPrintBon from "./ModalPrintBon";
const PaiementSalaire = () => {
    const { data: travailleurs, isloading } = UseFetch('personnel/gestion-salaire');
    const [selected, setSelected] = useState(null);
    const user = decryptData("USER");

    return (
        isloading ? <div className="loader"></div> :
            <div className="personnel">
                <div className="row justify-content-between align-items-center">
                    <div className="text-primary h4 col-lg-8">LISTE DU PERSONNEL</div>
                    <div className="text-primary h4 col-lg-4">
                        <SearchField placeholder='Rechercher un travailleur' plage='liste' conteneur='col-md-3' critere='name' />
                    </div>
                </div>

                <div className="row mt-2 liste">
                    {
                        travailleurs?.map((trav, ind) => (
                            <div className="col-md-3 p-1" key={ind}>
                                <div className="card shadow border-0 p-0 h-100 m-0">
                                    <div className="card-body  row gap-1 justify-content-center ">
                                        <img src={`${photoUrl}${trav.photo}`} alt="" className=" photo-travailleur" />
                                        <div className="col-lg-8 flex-grow-1  p-0 text-center">
                                            <h5 className="text-center p-0 my-0 name">
                                                {`${trav.firstname} ${trav.lastname?.toUpperCase()} `}</h5>
                                            <div className="text-center small">{trav.droit === 'Magasin' ? `Vendeur(Magasin ${trav.magasin.magasin})` : trav.droit}</div>
                                            <div className="text-center">
                                                {trav.phone && <span className="text-center text-secondary"><i className="bx bx-phone "></i> {trav.phone}<br /></span>}
                                                {trav.email && <span className="text-center text-secondary"><i className="bx bx-envelope "></i> {trav.email}</span>}
                                            </div>
                                            <div className="h6 my-1">Salaire: {trav?.salaire?.toLocaleString()} GNF <span data-bs-toggle='modal' data-bs-target='#modal-EditeSalaire' onClick={() => setSelected(trav)}><i className="bx bx-edit text-primary fs-4 pointer"></i></span></div>

                                            {trav.dette > 0 && <div className="h6 text-danger">Dette: {trav?.dette?.toLocaleString()} GNF
                                                <a className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modal-bon" onClick={() => setSelected(trav)}>imp. bon...</a></div>}
                                        </div>
                                    </div>
                                    {user?.droit === "Comptable" && <div className="card-footer bg-transparent text-end p-0">
                                        <button className="btn btn-secondary rounded-5 m-1" data-bs-toggle='modal' data-bs-target='#modal-avance' onClick={() => setSelected(trav)}>Avance salaire</button>

                                        <button className="btn btn-success rounded-5 m-1" data-bs-toggle='modal' data-bs-target='#modal-salaire' onClick={() => setSelected(trav)}>Payer salaire</button>
                                    </div>}
                                </div>
                            </div>
                        ))
                    }
                    <ModalAvanceSalaire travailleur={selected} />
                    <ModalPaieSalaire travailleur={selected} />
                    <ModalEditeSalaire travailleur={selected} />
                    <ModalPrintBon travailleur={selected} />

                </div>

            </div >
    );
}

export default PaiementSalaire;