import { NavLink, Outlet, useLocation } from "react-router-dom";
const Personnel = () => {
    const { pathname } = useLocation();
    return (
        <div className="personnel">
            <h4>GESTION DU PERSONNEL</h4>
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <NavLink to='/personnel/liste' className={`nav-link ${pathname === '/personnel/' ? 'active' : ''}`}>Liste</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to='/personnel/pointage' className={`nav-link ${pathname === '/personnel/pointage' ? 'active' : ''}`} >Pointage</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to='/personnel/rapport-presence' className={`nav-link ${pathname === '/personnel/rapport-presence' ? 'active' : ''}`} >Présence mensuel</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to='/personnel/classement' className={`nav-link ${pathname === '/personnel/classement' ? 'active' : ''}`} >Classement des vendeurs </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to='/personnel/salaire' className={`nav-link ${pathname === '/personnel/salaire' ? 'active' : ''}`} >Gestion salaires & Avances</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to='/personnel/rapport' className={`nav-link ${pathname === '/personnel/rapport' ? 'active' : ''}`} >Rapport paiement salaire</NavLink>
                </li>
            </ul>

            <Outlet />
        </div>
    );
}

export default Personnel;