import { useState } from "react";
import { decryptData, defaulUrl, Loading } from "../components/const";
const ModalPrintBon = ({ travailleur }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [toprintId, setToprintId] = useState(null)
    const avances = travailleur?.avances;
    const printbon = (id) => {
        if (!id) {
            return
        }
        let status = 0;
        setIsLoading(true)
        fetch(`${defaulUrl}bon/${id}`, {
            method: "GET",
            headers: {
                Accept: 'application/pdf',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            },
            responseType: 'blob'
        }).then((response) => {
            status = response.status;
            return response.blob()
        }).then(data => {
            setIsLoading(false);
            if (status === 200) {
                const url = window.URL.createObjectURL(data)
                const link = document.createElement('a')
                link.href = url
                link.download = `bon_${new Date()}.pdf`
                document.body.appendChild(link)
                link.click()
                window.URL.revokeObjectURL(url)
            }
        })
    }
    return (
        <div className="modal fade " id="modal-bon" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header bg-secondary-subtle">
                        <h1 className="modal-title fs-5" >Imprimer un bon</h1>
                        <button type="button " className="btn-close btn-danger" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="fomr-group">
                            <label htmlFor="">Avances</label>
                            <div className="input-group">
                                <select name="" id="" defaultValue={toprintId} className="form-select" onChange={(e) => setToprintId(e.target.value)}>
                                    <option value="">Chosir</option>
                                    {
                                        avances?.map((av, ind) => (
                                            <option value={av.id} key={ind}>
                                                {`${av?.montant?.toLocaleString()}-(${av?.created_at})`}
                                            </option>
                                        ))
                                    }
                                </select>
                                <button className="btn btn-primary" onClick={() => printbon(toprintId, setIsLoading())}><i className="bx bx-printer"></i></button>
                            </div>

                        </div>
                        {isLoading && <Loading />}
                    </div>
                </div>

            </div>
        </div>
    );
}

export default ModalPrintBon;