import { useRef, useState } from "react";
import UseFetch from "../components/UseFetch";
import { Loading, defaulUrl, decryptData } from "../components/const";
import { NavLink, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const AddMagasin = () => {
    let [filterArray, setFilter] = useState([]);
    const { data: pays, isloading } = UseFetch('pays/liste');
    const { data: villes, isloading: load } = UseFetch('villes/liste');
    const handleFilterVilleByPays = (e) => {
        filterArray = setFilter(villes.filter((v) => {

            return v.pays == e.target.value;
        }))
    }
    const navigate = useNavigate();
    let [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const handRegister = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formData = new FormData(e.target);
        let status = 0;
        fetch(`${defaulUrl}magasins/add`, {
            method: "POST",
            body: formData,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            }
        }).then((response) => {
            status = response.status;
            return response.json();
        }).then((data) => {
            setIsLoading(false);
            if (status == 200) {
                Swal.fire({
                    title: 'Succès',
                    text: data.message,
                    icon: 'success'
                }).then(() => {
                    navigate('/magasins/liste');
                })

                ref.current.click()
            } else {
                setErrors(data.errors);
            }

        })

    }

    const ref = useRef();

    return (
        <div className="AddMagasin">
            <div className="modal fade" id="modalAdd" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">AJOUTER UN MAGASIN</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={handRegister}>

                            <div className="modal-body">

                                <div className="row ">

                                    <div className="row p-3">
                                        <div className="col-lg-6 form-group my-2 p-3">
                                            <label htmlFor="magasin" className="fs-5">Libelé</label>
                                            <input type="text" className="form-control h-75" name="magasin" id="magasin" />
                                            {errors.magasin && <span className="text-danger">{errors.magasin}</span>}
                                        </div>

                                        <div className="col-lg-6 form-group my-2 p-3">
                                            <label htmlFor="telephone" className="fs-5">Telephone</label>
                                            <input type="number" className="form-control h-75 hide-arrow" name="telephone" id="telephone" />
                                            {errors.telephone && <span className="text-danger">{errors.telephone}</span>}
                                        </div>
                                        <div className="col-lg-6 form-group my-2 p-3">
                                            <label htmlFor="idpays" className="fs-5">Pays</label>
                                            <select name="idpays" id="idpays" className="form-select h-75" onChange={handleFilterVilleByPays}>
                                                <option value=""> {"Faites un choix"} </option>

                                                {pays &&
                                                    pays.map((pay) => (
                                                        <option key={pay.id} value={pay.pays}>{pay.pays}</option>
                                                    ))
                                                }
                                            </select>
                                            {errors.idpays && <span className="text-danger">{errors.idpays}</span>}
                                        </div>
                                        <div className="col-lg-6 form-group my-2 p-3">
                                            <label htmlFor="idville" className="fs-5">Ville</label>
                                            <select name="idville" id="idville" className="form-select h-75">
                                                <option value="">Choisir une ville</option>
                                                {filterArray &&
                                                    filterArray.map((ville) => (
                                                        <option key={ville.id} value={ville.id}>{ville.ville}</option>
                                                    ))
                                                }
                                            </select>
                                            {errors.idville && <span className="text-danger">{errors.idville}</span>}
                                        </div>
                                    </div>

                                </div>


                            </div>
                            <div className="modal-footer">
                                <button ref={ref} type="button" className="btn btn-danger" data-bs-dismiss="modal">Annuler</button>
                                <button className="btn btn-primary p-2 d-flex gap-1 justify-content-center align-items-center" disabled={isLoading}>{isLoading && <Loading />} Enregistrer</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default AddMagasin;