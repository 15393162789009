import { useEffect, useRef, useState } from "react";
import { formatMoney, onfocus, defaulUrl, decryptData, Loading } from "../components/const";
import Swal from "sweetalert2";
const ModalRemboursementFournisseur = ({ facture }) => {
    const ref = useRef();
    const [paye, setPaye] = useState(0)
    const [errors, setErrors] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [valeurejour, setValeurJour] = useState(1);
    const [payeGNF, setpayeGNF] = useState(0);
    const [source, setSource] = useState(null);
    const handlePayer = () => {

        if (paye <= 0 || paye === null) {
            setErrors({ 'montant': 'Entrez le montant paye en devise' });
            return
        }
        if (paye > (+facture?.reste)) {
            setErrors({ 'montant': 'Vous avez saisi un montant trop élévé!' });
            return
        }
        if (!source) {
            setErrors({ 'source': 'Veuillez choisir la source du fond!' });
            return
        }
        let status = 0;
        setIsLoading(true)
        const formData = new FormData();
        formData.set('idconteneur', facture?.idcont)
        formData.set('montant', +paye)
        formData.set('valeur_jour', +valeurejour);
        formData.set('source', source);

        fetch(`${defaulUrl}conteneur/payer`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                ContentType: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            },
            body: formData
        }).then((response) => {
            status = response.status;
            return response.json()
        }).then(data => {
            console.log(data)
            setIsLoading(false);
            if (status === 200) {
                Swal.fire({
                    title: 'Succès',
                    text: data.message,
                    icon: 'success'
                }).then(() => {
                    setPaye(0);
                    ref.current.click();
                })
                setErrors(null)
            } else {
                setErrors(data.errors);
            }
        })
        setErrors(null);
    }

    useEffect(() => {
        setpayeGNF(paye * valeurejour);
    }, [paye, valeurejour])
    return (

        <div className="modal fade " id="modalRemboursementFournisseur" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header bg-secondary-subtle">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Regler la facture # {facture?.numero}</h1>
                        <button type="button " className="btn-close btn-danger" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <span className="text-primary bg-secondary-subtle px-3">{`${facture?.prenoms} ${facture?.nom}`}({facture?.telephone})</span>
                    <form className="px-3">
                        <div className="modal-body position-relative">
                            <div className="form-group">
                                <label htmlFor="" className="fs-5">Montant à payer</label>
                                <div className="form-control hide-arrow p-2 bg-secondary-subtle">{`${facture?.reste?.toLocaleString()} ${facture?.devise}`} </div><div />
                            </div>
                            <label htmlFor="" className="fs-5">Valeur du jour</label>
                            <div className="form-group">
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" defaultValue={valeurejour}
                                        onChange={(e) => {
                                            setValeurJour(e.target.value.replace(/\s/g, ""));
                                        }}
                                        onBlur={(e) => formatMoney(e)}
                                        onFocus={(e) => onfocus(e)} />
                                    <span className="input-group-text" id="basic-addon2">GNF</span>
                                </div>

                            </div>
                            <label htmlFor="" className="fs-5">Montant payé</label>
                            <div className="form-group">
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" defaultValue={paye}
                                        onChange={(e) => {
                                            setPaye(e.target.value.replace(/\s/g, ""));
                                        }}
                                        onBlur={(e) => formatMoney(e)}
                                        onFocus={(e) => onfocus(e)} />
                                    <span className="input-group-text" id="basic-addon2">{facture?.devise}</span>
                                </div>
                                {errors?.montant && <span className="text-danger p-1">{errors?.montant}</span>
                                }
                            </div>
                            <label className="fs-5"> Mode de paiement</label>
                            <div className="d-flex justify-content-between">
                                <select name="source" id="" className="form-select" defaultValue={source} onChange={(e) => setSource(e.target.value)}>
                                    <option value="">Faites un choix</option>
                                    <option value="caisse">Caisse</option>
                                    <option value="Banque">Banque</option>
                                    <option value="Autres">Autres</option>
                                </select>

                                {errors?.source && <span className="text-danger p-1">{errors?.source}</span>
                                }
                            </div>
                            <label htmlFor="" className="fs-5 mt-2">Montant payé en GNF</label>
                            <div className="form-group">
                                <div className="d-flex justify-content-between bg-secondary-subtle">
                                    <div className="p-2 bg-secondary-subtle w-75 flex-grow-1">{payeGNF.toLocaleString()}</div>
                                    <span className="input-group-text" id="basic-addon2">GNF</span>
                                </div>
                            </div>
                            {isLoading && <Loading />}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" ref={ref}>Annuler</button>
                            <button type="button" className="btn btn-success" onClick={() => handlePayer()} disabled={isLoading}>Payer</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    );
}

export default ModalRemboursementFournisseur;