import { useState, useEffect, useRef } from "react";
import { defaulUrl, Loading, decryptData, deleteLocalStorage, formatMoney, onfocus, impression } from "../components/const";
import SearchField from "../components/SeachField";
import UseFetch from "../components/UseFetch";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import ModalAddFournisseur from "../Fournisseurs/ModalAddFournisseur";
const ConfirmConteneur = () => {
    const { data: produits, isloading } = UseFetch('produits/liste')
    const navigate = useNavigate()
    const location = useLocation();
    const conteneur = location.state.appro;
    // INFO FOUNISSEURS
    const [load, setLoad] = useState(false)
    // POUR CONTENEUR(CHOIX DES PRODUITS)
    let [prods, setProds] = useState(conteneur?.produits);
    const [removed, setRemoved] = useState([])
    const isSelected = (id) => {
        return prods?.find(p => p.id === id && p.quantite > 0) ?? false
    }
    const handleSelectProduct = (e, prod) => {
        if (!(prods?.find(p => p.id === prod.id))) {
            setProds([
                ...prods,
                {
                    'id': prod.id,
                    'quantite': 1,
                    'pau': 0,
                    'pvu': 0,
                    'produit': prod.produit,
                    'isnew':true
                }
            ]);
            setRemoved(removed.filter(elm => elm.id !== prod.id));


        }
        handleCalulePrixTotal()
    }
    // POUR LA DEFINITION DES PRIX ET QUANTITE
    const [totalPau, setTotalPau] = useState(0);
    const [qteTotal, setQteTotal] = useState(0);
    const [totalpvu, setTotalPvu] = useState(0);
    const [selectedCont, setSelectedCont] = useState(null);
    const [showPanier, setShowPanier] = useState(false);
    const printElemet = useRef()
    const handleQte = (e, prod) => {
        prod.quantite = e.target.value
        localStorage.setItem('selectedProducts', JSON.stringify(prods))
        handleCalulePrixTotal();
        handleCaluleQteTotal();
    }
    const handlePrix = (e, prod, isPvu = 0) => {
        isPvu ? prod.pvu = e.target.value.replace(/\D/g, "") : prod.pau = e.target.value.replace(/\D/g, "");
        localStorage.setItem('selectedProducts', JSON.stringify(prods))
        handleCalulePrixTotal()
    }
    const handleCalulePrixTotal = () => {
        setTotalPau(prods?.reduce(
            (previousValue, currentValue) => { return previousValue + (+currentValue.quantite * (+currentValue.pau)) },
            0));
        setTotalPvu(prods?.reduce(
            (previousValue, currentValue) => { return previousValue + (+currentValue.quantite * (+currentValue.pvu)) },
            0));
    }
    const handleCaluleQteTotal = () => {
        setQteTotal(prods?.reduce(
            (previousValue, currentValue) => { return previousValue + (+currentValue.quantite) },
            0));
    }
    const handleUnSelectProduct = (e, prod) => {
        e.preventDefault()
        if (prods?.find(p => p.id === prod.id)) {
            setProds(prods.filter(elm => elm.id !== prod.id))
        }
        if (!removed?.find(p => p.id === prod.id)) {
            setRemoved( [
                ...removed,
                prod
            ])
        }
        handleCalulePrixTotal()

    }
    const handleSave = () => {
        // Control de la saisie des information du fournisseurs
        if (prods?.length === 0) {
            Swal.fire({
                title: "Aucun produit selectionné!",
                text: "Veillez selectionner au moins un produit!",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            });
            return
        }
        let isvalide = -1;
        prods.forEach(p => {
            if (+p.quantite < 1 || +p.pau < 1 || +p.pvu < 1) {
                isvalide = +prods.indexOf(p) + 1;
            }
        });
        if (isvalide >= 0) {
            Swal.fire({
                title: "Erreur de saisie!",
                text: "Vériez les prix et la quantié sur la ligne " + isvalide,
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            });
            return
        }
        let status = 0;
        setLoad(true)
        fetch(`${defaulUrl}edite-cont/${conteneur.id}`, {
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + decryptData('ACCESS_TOKEN'),
                Accept: 'application/json',
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "produits": prods,
                'montant_achat': totalPau,
                'removed': removed
            })
        }).then((response) => {
            status = response.status;
            return response.json();

        }).then(data => {
            setLoad(false)
            if (status === 200) {
                Swal.fire({
                    title: "Succès",
                    text: data.message,
                    icon: "success",
                    showCancelButton: true,
                    confirmButtonText: 'Imprimer',
                    cancelButtonText: 'Quitter',
                    confirmButtonColor: 'green',
                    cancelButtonColor: 'red'
                }).then((confirm) => {
                    if (confirm.isConfirmed) {
                        impression(printElemet.current, 'portrait', `APPROVISION|MAGASIN:${conteneur?.magasin}|CONTENEUR: ${conteneur?.label}`, prods, qteTotal, totalPau, totalpvu)
                    }
                    deleteLocalStorage()
                    navigate(`/approvisions`)
                });
            } else {
                Swal.fire({
                    title: "Erreur",
                    text: data.message,
                    icon: "error"
                })
            }
        })
    }
    const handleCancel = () => {
        setProds(null);
        setSelectedCont(null);
        deleteLocalStorage();
        setShowPanier(false);
    }
    useEffect(() => {
        localStorage.setItem('selectedProducts', JSON.stringify(prods));
        handleCalulePrixTotal()
    }, [prods])
    useEffect(() => {
        handleCaluleQteTotal()
    }, [prods])
    return (
        <div className="add-conteneur">
            {load &&
                <div className="loader"></div>
            }
            <div className="container-fluid py-2 b">
                <h3 className="font-weight-light text-center text-muted py-3">CONFIRMER UN CONTENEUR</h3>

                <div className="col-lg-6 h4 p-2 bg-secondary-subtle"> Conteneur: {conteneur.conteneur?.toUpperCase()}</div>
                {!showPanier &&
                    <div className="row">
                        {(prods?.length > 0) && <button className="btn btn-primary m-1 btn-card shadow" onClick={() => setShowPanier(true)}>
                            <i className="bx bx-cart-alt fs-1 " ></i>
                            <span className="text-warning p-1 m-auto">{prods?.length}</span>
                        </button>}
                        <div className="col py-2">
                            <div className="card radius-15">
                                <div className="card-body">
                                    <h4 className="card-title text-muted">Choix des produits</h4>
                                    <div className="row p-2">

                                        <div className="Prouits">
                                            {
                                                isloading ? <Loading /> : <div className="ListeProduits">

                                                    <div className="row justify-content-end">
                                                        <div className="col-lg-4">
                                                            <SearchField plage='plage' conteneur='cont' critere='critere' />
                                                        </div>
                                                    </div>
                                                    {
                                                        produits ?

                                                            <div className="row  plage ">
                                                                {
                                                                    produits.map((prod) => (
                                                                        <div className="col-lg-3 cont flex-grow-1 p-1" key={prod.id} >
                                                                            <div className="card rounded pointer h-100 card-magasin p-2 m-1">

                                                                                <span className="text-center critere fw-bold h5">{prod.produit}</span>
                                                                                <div className="modal-footer">
                                                                                    {isSelected(prod.id) &&
                                                                                        <span><i className="bx bx-checkbox-checked fs-4 text-success" ></i></span>
                                                                                    }
                                                                                    {!isSelected(prod.id) && <button className="btn btn-primary " onClick={(e) => handleSelectProduct(e, prod)} >Choisir</button>}
                                                                                    {isSelected(prod.id) && <button className="btn btn-danger" onClick={(e) => handleUnSelectProduct(e, prod)} >Retirer</button>}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>

                                                            : <div className="row p-3 m-2 bg-danger-subtle">
                                                                <h5 className="text-center">Aucun produit n'a ete enregister</h5>
                                                            </div>
                                                    }
                                                </div>
                                            }


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}

                {showPanier &&
                    <div className="row mx-1">

                        <div className="card radius-15">
                            <div className="card-body">
                                <h4 className="card-title">Saisie des Quantiés & Prix</h4>
                                <div className="row p-2" ref={printElemet}>
                                    <div className="table-responsive p-2 mt-2">
                                        {prods?.length ?
                                            <table className="table table-hover table-striped table-secondary  table-bordered">
                                                <thead className="table-dark">
                                                    <tr>
                                                        <td hidden>ID</td>
                                                        <td >N°</td>
                                                        <td>Designation Produit</td>
                                                        <td >PAU</td>
                                                        <td>Qte</td>
                                                        <td >PVU</td>
                                                        <td className="text-end">Action</td>
                                                    </tr>
                                                </thead>
                                                <tbody id="selectedProducts">
                                                    {
                                                        prods.map((pro, i) => (
                                                            <tr key={pro.id}>
                                                                <td hidden className="id"> {pro.id}</td>
                                                                <td > {i + 1}</td>
                                                                <td className="prod"> {pro.produit}</td>
                                                                <td >
                                                                    <div className="input-group mb-3">
                                                                        <input type="text" className="form-control" placeholder="Entrez le prix unitaire" defaultValue={pro.pau?.toLocaleString()}
                                                                            onChange={(e) => {
                                                                                handlePrix(e, pro, 0)
                                                                            }}
                                                                            onBlur={(e) => formatMoney(e)}
                                                                            onFocus={(e) => onfocus(e)} />
                                                                        <span className="input-group-text" id="basic-addon2">GNF</span>
                                                                    </div>
                                                                </td>
                                                                <td className=" text-end qte">
                                                                    <input type="number" min={1} className="form-control" defaultValue={+pro.quantite} onChange={(e) => handleQte(e, pro)} />

                                                                </td>
                                                                <td >
                                                                    <div className="input-group mb-3">
                                                                        <input type="text" className="form-control" placeholder="Entrez le prix unitaire" defaultValue={pro.pvu?.toLocaleString()}
                                                                            onChange={(e) => handlePrix(e, pro, 1)}
                                                                            onBlur={(e) => formatMoney(e)}
                                                                            onFocus={(e) => onfocus(e)} />
                                                                        <span className="input-group-text" id="basic-addon2">GNF</span>
                                                                    </div>
                                                                </td>
                                                                <td className="text-end">

                                                                    <button onClick={(e) => handleUnSelectProduct(e, pro)} className="btn btn-danger m-1" >Retirer</button>
                                                                </td>
                                                            </tr>

                                                        ))
                                                    }
                                                    <tr className="border-0">
                                                        <td colSpan={2} className="text-center fs-4 fw-bold border"> Totaux</td>
                                                        <td className="border fw-bold">{`${totalPau?.toLocaleString()} GNF`}</td>
                                                        <td className="border fw-bold">{qteTotal?.toLocaleString()}</td>
                                                        <td className="border fw-bold">{`${totalpvu?.toLocaleString()} GNF`}</td>
                                                    </tr>
                                                </tbody>
                                            </table> :
                                            <div className="bg-danger-subtle text-danger w-100 text-center p-2 fs-3 ">
                                                Vous n'avez pas choisi un produit!
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer mb-2">
                                <button className="btn btn-primary me-5" onClick={() => setShowPanier(false)}>
                                    <i className="bx bx-chevron-left"></i>Retour</button>
                                <button className="btn btn-danger m-1" onClick={handleCancel}>Annuler</button>
                                <button className="btn btn-success m-1" onClick={() => handleSave()} disabled={load}>Enregistrer</button>
                            </div>
                        </div>
                    </div>
                }
            </div>

            <ModalAddFournisseur />
        </div>
    );
}

export default ConfirmConteneur;