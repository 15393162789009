import { useState, useEffect } from "react";
import { defaulUrl, Loading, decryptData, deleteLocalStorage, formatMoney, onfocus } from "../components/const";
import SearchField from "../components/SeachField";
import UseFetch from "../components/UseFetch";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import ModalAddFournisseur from "../Fournisseurs/ModalAddFournisseur";
import ModalAddAgent from "../Personnel/ModalAddAgent";

const Addconteneur = () => {
    const { data: produits, isloading } = UseFetch('produits/liste')
    const { data: fournisseurs, isloading: loader } = UseFetch(`fournisseurs/liste`);
    const { data: conteneurs, isloading: loadcont } = UseFetch(`conteneurs/liste`);
    const { data: transitaires, isloading: loading } = UseFetch(`transitaires`);

    const navigate = useNavigate()
    // INFO FOUNISSEURS
    let [fournisseur, setFournisseur] = useState(null);
    const [load, setLoad] = useState(false)
    // POUR CONTENEUR(CHOIX DES PRODUITS)
    let selectedProds = localStorage.getItem('selectedProducts') ? JSON.parse(localStorage.getItem('selectedProducts')) : null;
    let [prods, setProds] = useState(selectedProds ? selectedProds : []);
    const isSelected = (id) => {
        return prods?.find(p => p.id === id && p.quantite > 0) ?? false
    }
    const handleSelectProduct = (e, prod) => {
        if (!(prods?.find(p => p.id === prod.id))) {
            setProds([
                ...prods,
                {
                    'id': prod.id,
                    'quantite': 1,
                    'prix': 0,
                    'produit': prod.produit,
                }
            ]);
        }
        handleCalulePrixTotal()
    }
    // POUR LA DEFINITION DES PRIX ET QUANTITE
    const [totalPrix, setTotalPrix] = useState(0);
    const [conteneur, setConteneur] = useState('');
    const [devise, setDevise] = useState('');
    const [valeurjour, setValeurJour] = useState(1);
    const [prixConteneur, setPrixConteneur] = useState(0);//PRIX DU CONTENEUR EN DEVISE
    const [qteTotal, setQteTotal] = useState(0);
    const [error, setError] = useState(null);
    const [selectedCont, setSelectedCont] = useState(null);
    const [showPanier, setShowPanier] = useState(false);
    // DEPENSES
    const [depenseTotal, setDepenseTotal] = useState(0);
    const [transitaire, setTransitaire] = useState(null);
    const [primeTransit, setPrimeTransit] = useState(0);
    const [primeTravailleurs, setPrimeTravailleurs] = useState(0);
    const [loyerMagasin, setloyerMagasin] = useState(0);
    const [autreDep, setAutreDep] = useState(0);

    // informations du conteneur
    let MyInfo = {
        'lib_conteneur': conteneur,
        'devise': devise,
        'prix_total': parseFloat(prixConteneur),
        'valeur_du_jour': +valeurjour,
        'depense': + depenseTotal,
        'montant_achat': +totalPrix,
        'montant_total': (+valeurjour * prixConteneur) + (+depenseTotal),
    }

    const handleQte = (e, prod) => {
        prod.quantite = e.target.value
        localStorage.setItem('selectedProducts', JSON.stringify(prods))
        handleCalulePrixTotal();
        handleCaluleQteTotal();
    }
    const handlePrix = (e, prod) => {
        prod.prix = e.target.value.replace(/\D/g, "");
        localStorage.setItem('selectedProducts', JSON.stringify(prods))
        handleCalulePrixTotal()
    }
    const handleCalulePrixTotal = () => {
        setTotalPrix(prods?.reduce(
            (previousValue, currentValue) => { return previousValue + (+currentValue.quantite * (+currentValue.prix)) },
            0));
    }
    const handleCaluleQteTotal = () => {
        setQteTotal(prods?.reduce(
            (previousValue, currentValue) => { return previousValue + (+currentValue.quantite) },
            0));
    }
    const handleUnSelectProduct = (e, prod) => {
        e.preventDefault()
        if (prods?.find(p => p.id === prod.id)) {
            setProds(prods.filter(elm => elm.id !== prod.id))
        }
        handleCalulePrixTotal()

    }
    const handleSave = () => {
        // Control de la saisie des information du fournisseurs
        if (!fournisseur) {
            Swal.fire({
                title: "Saisie obligatoire!",
                text: "Veillez selectionner le fournisseur",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            });
            return
        }
        if (!devise) {
            Swal.fire({
                title: "Saisie obligatoire!",
                text: "Veillez selectionner la devise des prix dans la section information du conteneur",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            });
            return
        }
        if (!conteneur) {
            Swal.fire({
                title: "Saisie obligatoire!",
                text: "Veillez saisir le libelé du conteneur dans la section information du conteneur",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            });
            return
        }
        if (!transitaire) {
            Swal.fire({
                title: "Choix obligatoire!",
                text: "Veillez choisir un transitaire",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            });
            return
        }
        if (prods?.length === 0) {
            Swal.fire({
                title: "Aucun produit selectionné!",
                text: "Veillez selectionner au moins un produit!",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            });
            return
        }
        let isvalide = -1;
        prods.forEach(p => {
            if (+p.quantite < 1 || +p.prix < 1) {
                isvalide = +prods.indexOf(p) + 1;
            }
        });
        if (isvalide >= 0) {
            Swal.fire({
                title: "Erreur de saisie!",
                text: "Vériez le prix et la quantié sur la ligne " + isvalide,
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            });
            return
        }

        if (!valeurjour) {
            Swal.fire({
                title: "Saisie obligatoire!",
                text: "Veillez saisir la valeur du jour des produits en GNF!",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            });
            return

        }
        const depenses = {
            'idtransit': transitaire?.value,
            'prime_transitaire': primeTransit,
            'prime_travailleurs': primeTravailleurs,
            'loyer_mag': loyerMagasin,
            'autre_dep': autreDep
        }
        let status = 0;
        setLoad(true)
        fetch(`${defaulUrl}conteneurs/ajout`, {
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + decryptData('ACCESS_TOKEN'),
                Accept: 'application/json',
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "fournisseur": fournisseur,
                "produits": prods,
                "conteneur": MyInfo,
                'depenses': depenses
            })
        }).then((response) => {
            status = response.status;
            return response.json();

        }).then(data => {
            setLoad(false)
            if (status === 200) {
                Swal.fire({
                    title: "Succès",
                    text: data.message,
                    icon: "success"
                })
                deleteLocalStorage()
                navigate(`/conteneurs`)
            } else {
                Swal.fire({
                    title: "Erreur",
                    text: data.message,
                    icon: "error"
                })
            }
        })
    }

    const handleCancel = () => {
        setProds(null);
        setConteneur('');
        setDevise('');
        setPrixConteneur(0);
        setSelectedCont(null);
        deleteLocalStorage();
        setShowPanier(false);
    }
    useEffect(() => {
        setDepenseTotal(primeTransit + primeTravailleurs + loyerMagasin + autreDep)
    }, [primeTransit, primeTravailleurs, loyerMagasin, autreDep])
    useEffect(() => {
        localStorage.setItem('selectedProducts', JSON.stringify(prods));
        handleCalulePrixTotal()
    }, [prods, depenseTotal])

    useEffect(() => {
        handleCaluleQteTotal()
    }, [prods])

    const handleCharger = () => {
        if (!selectedCont) {
            setError("Entrez le numero du conteneur!");
            return
        }
        setLoad(true)
        fetch(`${defaulUrl}stocks/liste/${+selectedCont?.value}`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            }
        }).then((response) => {
            return response.json();
        }).then(data => {
            setLoad(false)
            setProds(data)
        })
        setError(null);
    }

    return (
        <div className="add-conteneur">
            {load &&
                <div className="loader"></div>
            }
            <div className="container-fluid py-2 b">
                <h2 className="font-weight-light text-center text-muted py-3">CREATION DE CONTENEUR</h2>
                <div className="row">
                    <div className="col-lg-6 py-1 ">
                        <h4 className="text-secondary">Fournisseur</h4>
                        <div className="input-group">
                            {loader ? <Select /> :
                                <Select className="w-75"
                                    defaultInputValue={fournisseur?.label}
                                    options={
                                        fournisseurs?.map((fourn) => (
                                            {
                                                value: `${fourn.id}`, label: `${fourn.prenoms} ${fourn.nom}(${fourn.telephone})`
                                            }
                                        ))
                                    }
                                    onChange={setFournisseur}
                                />
                            }
                            <a className="input-group-text bg-transparent btn btn-primary text-dark" data-bs-toggle='modal' data-bs-target='#modalAddFournisseur'>
                                <i className='bx bx-plus-circle'></i>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="row" >
                    <div className="col py-2">
                        <div className="card border-primary shadow radius-15">
                            <div className="card-body" >
                                <h4 className="card-title text-muted">Informations du conteneur</h4>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <fieldset className="rounded">
                                            <legend>Conteneur</legend>
                                            <div className="form-group ">
                                                <h5 className="card-title text-primary">Libelé du Conteneur</h5>
                                                <input type="text" className="form-control" name="conteneur" id="conteneur"
                                                    defaultValue={conteneur}
                                                    onChange={(e) => {
                                                        setConteneur(e.target.value);
                                                        localStorage.setItem('storedInfo', JSON.stringify(MyInfo));
                                                    }} />
                                            </div>
                                            <div className="row my-2">
                                                <div className="form-group col-lg-6">
                                                    <h5 className="card-title text-primary">Devise</h5>
                                                    <select name="devise" id="deise" className="form-select" defaultValue={devise} onChange={(e) => {
                                                        setDevise(e.target.value)
                                                        localStorage.setItem('storedInfo', JSON.stringify(MyInfo));
                                                    }}>
                                                        <option value="">Choisir une devise</option>
                                                        <option value="GNF">GNF</option>
                                                        <option value="CFA">CFA</option>
                                                        <option value="$">$</option>
                                                        <option value="Euro">Euro</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <h5 className="card-title text-primary">Valeur du jour</h5>
                                                    <div className="input-group mb-3">
                                                        <input type="text" className="form-control" placeholder="Valeur du jour" defaultValue={valeurjour} onChange={(e) => {
                                                            setValeurJour(+e.target.value)
                                                            localStorage.setItem('storedInfo', JSON.stringify(MyInfo));
                                                        }}
                                                            onBlur={(e) => formatMoney(e)}
                                                            onFocus={(e) => onfocus(e)} />
                                                        <span className="input-group-text" id="basic-addon2">GNF</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mb-1">
                                                <div className="form-group  col-lg-6">
                                                    <h5 className="card-title text-primary">Prix du conteneur en devise</h5>
                                                    <div className="input-group mb-3">
                                                        <input type="text" className="form-control" placeholder="Entrez le Montant facture en devise" defaultValue={prixConteneur} onChange={(e) => {
                                                            setPrixConteneur(+e.target.value)
                                                            localStorage.setItem('storedInfo', JSON.stringify(MyInfo));
                                                        }}
                                                            onBlur={(e) => formatMoney(e)}
                                                            onFocus={(e) => onfocus(e)} />
                                                        <span className="input-group-text" id="basic-addon2">{devise}</span>
                                                    </div>
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <h5 className="card-title text-primary">Montant total en GNF</h5>
                                                    <div className="input-group mb-3">
                                                        <input type="text" className="form-control" placeholder="Valeur du jour" value={((prixConteneur * valeurjour) + depenseTotal)?.toLocaleString()} disabled />
                                                        <span className="input-group-text" id="basic-addon2">GNF</span>
                                                    </div>
                                                    {/* <div className="input-group mb-3">
                                                        <div className="p-2 bg-secondary-subtle  w-75">
                                                            {((prixConteneur * valeurjour) + depenseTotal)?.toLocaleString()}

                                                        </div>
                                                        <span className="input-group-text" id="basic-addon2">GNF</span>
                                                        <small className="text-secondary">(prix conteneur * valeur jour)+depense</small>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div className="col-lg-6">
                                        <fieldset className="rounded">
                                            <legend>Dépenses</legend>
                                            <div className="row my-2">
                                                <div className="form-group col-lg-6">
                                                    <h5 className="card-title text-primary">Agent transitaire</h5>
                                                    {loader ? <Select /> :

                                                        <Select
                                                            defaultInputValue={transitaire?.label}
                                                            options={
                                                                transitaires?.map((trans) => (
                                                                    {
                                                                        value: `${trans.id}`, label: `${trans.prenom} ${trans.nom}(${trans.telephone})`
                                                                    }
                                                                ))
                                                            }
                                                            onChange={setTransitaire}
                                                        />

                                                    }
                                                    {/* <a className="input-group-text bg-transparent btn btn-primary text-dark" data-bs-toggle='modal' data-bs-target='#addAgent'>
                                                    <i className='bx bx-plus-circle'></i>
                                                </a> */}
                                                </div>

                                                <div className="form-group  col-lg-6">
                                                    <h5 className="card-title text-primary">Prime transitaire</h5>
                                                    <div className="input-group mb-3">
                                                        <input type="text" className="form-control" placeholder="Entrez le Montant facture en devise" defaultValue={primeTransit} onChange={(e) => {
                                                            setPrimeTransit(+e.target.value)
                                                        }}
                                                            onBlur={(e) => formatMoney(e)}
                                                            onFocus={(e) => onfocus(e)} />
                                                        <span className="input-group-text" id="basic-addon2">GNF</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-lg-6">
                                                    <h5 className="card-title text-primary">Prime travailleurs</h5>
                                                    <div className="input-group mb-3">
                                                        <input type="text" className="form-control" placeholder="Valeur du jour" defaultValue={primeTravailleurs} onChange={(e) => {
                                                            setPrimeTravailleurs(+e.target.value)
                                                            localStorage.setItem('storedInfo', JSON.stringify(MyInfo));
                                                        }}
                                                            onBlur={(e) => formatMoney(e)}
                                                            onFocus={(e) => onfocus(e)} />
                                                        <span className="input-group-text" id="basic-addon2">GNF</span>
                                                    </div>
                                                </div>
                                                <div className="form-group  col-lg-6">
                                                    <h5 className="card-title text-primary">Loyer magasin</h5>
                                                    <div className="input-group mb-3">
                                                        <input type="text" className="form-control" placeholder="Entrez le Montant facture en devise" defaultValue={loyerMagasin} onChange={(e) => {
                                                            setloyerMagasin(+e.target.value)
                                                        }}
                                                            onBlur={(e) => formatMoney(e)}
                                                            onFocus={(e) => onfocus(e)} />
                                                        <span className="input-group-text" id="basic-addon2">GNF</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group  col-lg-6">
                                                    <h5 className="card-title text-primary">Autres</h5>
                                                    <div className="input-group mb-3">
                                                        <input type="text" className="form-control" placeholder="Entrez le Montant facture en devise" defaultValue={autreDep} onChange={(e) => {
                                                            setAutreDep(+e.target.value)
                                                        }}
                                                            onBlur={(e) => formatMoney(e)}
                                                            onFocus={(e) => onfocus(e)} />
                                                        <span className="input-group-text" id="basic-addon2">GNF</span>
                                                    </div>
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <h5 className="card-title text-primary">Dépense totale</h5>
                                                    <div className="input-group mb-3">
                                                        <input type="text" className="form-control" placeholder="Entrez la depense" value={depenseTotal?.toLocaleString()}
                                                            disabled />
                                                        <span className="input-group-text" id="basic-addon2">GNF</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {!showPanier &&
                    <div className="row">
                        {(prods?.length > 0) && <button className="btn btn-primary m-1 btn-card shadow" onClick={() => setShowPanier(true)}>
                            <i className="bx bx-cart-alt fs-1 " ></i>
                            <span className="text-warning p-1 m-auto">{prods?.length}</span>
                        </button>}
                        <div className="col py-2">
                            <div className="card radius-15">
                                <div className="card-body">
                                    <h4 className="card-title text-muted">Choix des produits</h4>
                                    <div className="row p-2">

                                        <div className="Prouits">
                                            {
                                                isloading ? <Loading /> : <div className="ListeProduits">

                                                    <div className="row justify-content-between">
                                                        <div className="col-lg-6 form-group">
                                                            <label htmlFor="" className="text-primary">Charger à partir d'un conteneur?</label>
                                                            <div className="input-group">

                                                                {loadcont ? <Select /> :
                                                                    <Select
                                                                        defaultInputValue={conteneur?.label}
                                                                        options={
                                                                            conteneurs.map((cont) => (
                                                                                {
                                                                                    value: `${cont.id}`, label: `${cont.conteneur}`
                                                                                }
                                                                            ))
                                                                        }
                                                                        onChange={setSelectedCont}
                                                                        className="w-75" />
                                                                }

                                                                <span className=" bg-secondary-subtle px-2 rounded-end" id="basic-addon2" onClick={handleCharger}>
                                                                    <i className="bx bx-refresh fs-4 text-primary"></i>
                                                                </span>
                                                            </div>
                                                            {error && <span className="text-danger">{error}</span>}
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <SearchField plage='plage' conteneur='cont' critere='critere' />
                                                        </div>
                                                    </div>
                                                    {
                                                        produits ?

                                                            <div className="row  plage ">
                                                                {
                                                                    produits.map((prod) => (
                                                                        <div className="col-lg-3 cont flex-grow-1 p-1" key={prod.id} >
                                                                            <div className="card rounded pointer h-100 card-magasin p-2 m-1">

                                                                                <span className="text-center critere fw-bold h5">{prod.produit}</span>
                                                                                <div className="modal-footer">
                                                                                    {isSelected(prod.id) &&
                                                                                        <span><i className="bx bx-checkbox-checked fs-4 text-success" ></i></span>
                                                                                    }
                                                                                    {!isSelected(prod.id) && <button className="btn btn-primary " onClick={(e) => handleSelectProduct(e, prod)} >Choisir</button>}
                                                                                    {isSelected(prod.id) && <button className="btn btn-danger" onClick={(e) => handleUnSelectProduct(e, prod)} >Retirer</button>}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>


                                                            : <div className="row p-3 m-2 bg-danger-subtle">
                                                                <h5 className="text-center">Aucun produit n'a ete enregister</h5>
                                                            </div>
                                                    }
                                                </div>
                                            }


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}

                {showPanier &&
                    <div className="row mx-1">

                        <div className="card radius-15">
                            <div className="card-body">
                                <h4 className="card-title">Saisie des Quantiés & Prix</h4>
                                <div className="row p-2">
                                    <div className="table-responsive p-2 mt-2">
                                        {prods?.length ?
                                            <table className="table table-hover table-striped table-secondary  table-bordered">
                                                <thead className="table-dark">
                                                    <tr>
                                                        <td hidden>ID</td>
                                                        <td >N°</td>
                                                        <td>Designation Produit</td>
                                                        <td>Qte</td>
                                                        <td >PAU</td>
                                                        <td className="text-end">Action</td>
                                                    </tr>
                                                </thead>
                                                <tbody id="selectedProducts">
                                                    {
                                                        prods.map((pro, i) => (
                                                            <tr key={pro.id}>
                                                                <td hidden className="id"> {pro.id}</td>
                                                                <td > {i + 1}</td>
                                                                <td className="prod"> {pro.produit}</td>
                                                                <td className=" text-end qte">
                                                                    <input type="number" min={1} className="form-control" defaultValue={+pro.quantite} onChange={(e) => handleQte(e, pro)} />

                                                                </td>
                                                                <td >

                                                                    <div className="input-group mb-3">
                                                                        <input type="text" className="form-control" placeholder="Entrez le prix unitaire" defaultValue={pro.prix} onChange={(e) => handlePrix(e, pro)} onBlur={(e) => formatMoney(e)}
                                                                            onFocus={(e) => onfocus(e)} />
                                                                        <span className="input-group-text" id="basic-addon2">GNF</span>
                                                                    </div>

                                                                </td>
                                                                <td className="text-end">

                                                                    <button onClick={(e) => handleUnSelectProduct(e, pro)} className="btn btn-danger m-1" >Retirer</button>
                                                                </td>
                                                            </tr>

                                                        ))
                                                    }
                                                    <tr className="border-0">
                                                        <td colSpan={2} className="text-center fs-4 fw-bold border"> Totaux</td>
                                                        <td className="border fw-bold">{qteTotal?.toLocaleString()}</td>
                                                        <td className="border fw-bold">{`${totalPrix?.toLocaleString()} GNF`}</td>
                                                    </tr>
                                                </tbody>
                                            </table> :
                                            <div className="bg-danger-subtle text-danger w-100 text-center p-2 fs-3 ">
                                                Vous n'avez pas choisi un produit!
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer mb-2">
                                <button className="btn btn-primary me-5" onClick={() => setShowPanier(false)}>
                                    <i className="bx bx-chevron-left"></i>Retour</button>
                                <button className="btn btn-danger m-1" onClick={handleCancel}>Annuler</button>
                                <button className="btn btn-success m-1" onClick={() => handleSave()} disabled={load}>Enregistrer</button>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <ModalAddAgent url='transitaires' />
            <ModalAddFournisseur />
        </div>
    );
}

export default Addconteneur;