import { useEffect, useState } from "react";
import SearchField from "../components/SeachField";
import UseFetch from "../components/UseFetch";
import Swal from "sweetalert2";
import { decryptData, defaulUrl } from "../components/const";
import AddUser from "../Authentification/AddUser";

const ListePers = () => {
    const { data: personnel, isloading } = UseFetch('personnel');
    const [liste, setList] = useState(personnel)
    const [loading, setLoading] = useState(false)
    const handleTrie = (valeur) => {
        if (valeur != 'tout') {
            setList(personnel?.filter((pers) => pers.status === valeur))
        } else {
            setList(personnel)
        }
    }


    const handleAction = (action, pers, status) => {
        Swal.fire({
            title: 'Gestion personnel',
            text: `Voulez-vous ${action} cet travailleur?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'OUI',
            cancelButtonText: 'Annuler'
        }).then(confim => {
            if (confim.isConfirmed) {
                pers.status = status;
                if (status != 'En fonction') {
                    pers.statut = 'Bloqué'
                } else {
                    pers.statut = 'Actif'
                }
                let statuCode = 0;
                setLoading(true)

                let fomdata = new FormData()
                for (var key in pers) {
                    fomdata.append(key, pers[key]);
                }

                fetch(`${defaulUrl}personnel-saction/${pers.id}`, {
                    method: "POST",
                    headers: {
                        Accept: 'application/json',
                        ContentType: 'application/json',
                        Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
                    },
                    body: fomdata
                }).then(response => {
                    statuCode = response.status
                    return response.json()
                }).then(data => {
                    console.log(data)
                    setLoading(false)
                    if (statuCode === 200) {
                        Swal.fire({
                            title: "Succès",
                            text: data.message,
                            icon: 'success'
                        })
                    } else {
                        Swal.fire({
                            title: "Erreur",
                            text: data.message,
                            icon: 'error'
                        })
                    }
                })
            }
        })
    }
    useEffect(() => {
        setList(personnel)
    }, [isloading])
    return (
        isloading ? <div className="loader"></div> :
            <div className="table-responsive mt-3 ">
                {loading && <div className="loader"></div>}
                <div className="row justify-content-end">
                    <div className="col-lg-3 text-end">
                        <button className='btn btn-primary' data-bs-toggle="modal" data-bs-target="#modalUser">Nouveau travailleur</button>

                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="row  align-items-center">
                            <label htmlFor="colFormLabelSm" className="col-sm-2 col-form-label col-form-label-sm w-auto">Trier par: </label>
                            <div className="col-sm-10  h-100">
                                <select name="" id="" className="form-select" onChange={(e) => handleTrie(e.target.value)}>
                                    <option value="tout" defaultChecked >Tous</option>
                                    <option value="En fonction" defaultChecked >En fonction</option>
                                    <option value="Sanctionné(e)" defaultChecked >Sanctionné(e)</option>
                                    <option value="Licencié(e)" defaultChecked >Licencié(e)</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <SearchField plage='plage' conteneur='tr' critere='critere' />
                    </div>
                </div>
                <table className="table table-striped table-bordered table-hover">
                    <thead className="table-dark">
                        <tr className="align-middle text-center">
                            <th>N°</th>
                            <th>PRENOMS & NOM</th>
                            <th>TELEPHONE</th>
                            <th>EMAIL</th>
                            <th>FONCTION</th>
                            <th>MAGASIN</th>
                            <th>STATUT</th>
                            <th className="text-end">ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody className="plage">
                        {
                            liste?.map((pers, ind) => (
                                <tr className="align-middle tr" key={ind}>
                                    <td>{ind + 1}</td>
                                    <td className="critere">{(pers.firstname + ' ' + pers.lastname)?.toUpperCase()}</td>
                                    <td>{pers.phone}</td>
                                    <td>{pers.email}</td>
                                    <td>{`${pers.droit === 'Magasin' ? 'Vendeur' : pers.droit}`}</td>
                                    <td>{pers.magasin?.magasin?.toUpperCase()}</td>
                                    <td>{pers.status}</td>
                                    <td className="text-end">
                                        <a className="dropdown-toggle dropdown-toggle-nocaret" href="#"
                                            data-bs-toggle="dropdown"  >
                                            <i className='bx bx-dots-vertical-rounded font-22 text-option px-2 bg-primary-subtle shadow rounded-circle'></i>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-end">
                                            <div className="row row-cols-2 g-1 p-1">
                                                <div className="col text-center">
                                                    <button className="btn btn-danger mx-auto  "
                                                        disabled={pers.status != 'En fonction'}
                                                        onClick={() => handleAction('Sactionner', pers, 'Sanctionné(e)')}
                                                    >
                                                        <i className='bx bx-atom'></i>
                                                    </button>
                                                    <div className="app-title">Sanctionner</div>
                                                </div>
                                                <div className="col text-center">
                                                    <button className="btn btn-success mx-auto"
                                                        disabled={pers.status != 'Sanctionné(e)'}
                                                        onClick={() => handleAction('Lever la sanction de', pers, 'En fonction')}
                                                    >
                                                        <i className='bx bx-shield'></i>
                                                    </button>
                                                    <div className="app-title">Lever la sanction</div>
                                                </div>
                                                <div className="col text-center">
                                                    <button className="btn btn-danger mx-auto "
                                                        disabled={pers.status === 'Licencié(e)'}
                                                        onClick={() => handleAction('Licencier', pers, 'Licencié(e)')}
                                                    >
                                                        <i className='bx bx-no-entry'></i>
                                                    </button>
                                                    <div className="app-title">Licencier</div>
                                                </div>
                                                <div className="col text-center">
                                                    <button className=" btn btn-success mx-auto  "
                                                        disabled={pers.status != 'Licencié(e)'}
                                                        onClick={() => handleAction('Repprendre', pers, 'En fonction')}
                                                    >
                                                        <i className='bx bx-notification'></i>
                                                    </button>
                                                    <div className="app-title">Repprendre</div>
                                                </div>

                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                <AddUser isuser={0} />
            </div>
    );
}

export default ListePers;