import { useParams, NavLink, Outlet, useLocation } from "react-router-dom";
import SearchField from "../components/SeachField";
import { impression, Loading } from "../components/const";
import UseFetch from "../components/UseFetch";
import { act, useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
const StockMagasin = ({ idmag }) => {
    let { id } = useParams();
    if (idmag) {
        id = idmag
    }
    const { data: conteneurs, isloading } = UseFetch(`approvisions/liste/${id}`);
    const { data: stocks, isloading: loanding } = UseFetch(`mag/${id}/stocks`);

    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString();
    let [date1, setDate1] = useState(null);
    let [date2, setDate2] = useState(null);
    const [stockMois, setByMonth] = useState(null)
    const [totalQte, setTotalQte] = useState(0);
    const [totalMontantPAU, setTotalMontantPAU] = useState(0);
    const [totalMontantPVU, setTotalMontantPVU] = useState(0);
    const [qteVendu, setQteVendu] = useState(0);
    const [MontantVendu, setMontantVendu] = useState(0);
    const [qteReste, setQteReste] = useState(0);
    const [montantReste, setMontantReste] = useState(0);

    const [selected_totalQte, setSelected_TotalQte] = useState(0);
    const [selected_totalMontantPAU, setSelected_TotalMontantPAU] = useState(0);
    const [selected_totalMontantPVU, setSelected_TotalMontantPVU] = useState(0);
    const [selected_qteVendu, setSelected_QteVendu] = useState(0);
    const [selected_MontantVendu, setSelected_MontantVendu] = useState(0);
    const [selected_qteReste, setSelected_QteReste] = useState(0);
    const [selected_montantReste, setSelected_MontantReste] = useState(0);

    const [active, setActive] = useState('actuel');

    const stockAttente = conteneurs ? conteneurs.filter(cont => cont.statut === 0) : null
    const ByMonthstockMois = conteneurs ? conteneurs.filter(cont => cont.statut === 1) : null
    let infoConteneur = stocks?.map(p => ({ 'numero': p.numero, 'conteneur': p.conteneur }))
    infoConteneur = [...new Map(infoConteneur?.map(o => [o.numero, o])).values()]//elimine les doublons
    const [selectedConteneur, setSelectedConteneur] = useState(null);
    const printable = useRef()
    const printConteneur = useRef()



    const handleTriByDate = (e) => {
        if (!date1 || !date2) {
            Swal.fire({
                title: "Date(s) invalides!",
                text: "Choisissez de bonnes dates pour date1 et date 2 ",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            });
            return;
        }
        if (date1 > date2) {

            Swal.fire({
                title: "Date(s) invalides!",
                text: "La  date 2 doit être supérieure a la date 1 ",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            });
            return;
        }
        e.preventDefault();
        setByMonth(ByMonthstockMois.filter((appro) => appro.updated_at >= date1 && appro.updated_at <= date2 + 1));
    }
    useEffect(() => {
        if (stocks) {
            setTotalQte(stocks?.reduce((previous, current) => { return previous + (+current.quantite) }, 0));
            setTotalMontantPAU(stocks?.reduce((previous, current) => { return previous + (+current.quantite * +current.pau) }, 0));
            setTotalMontantPVU(stocks?.reduce((previous, current) => { return previous + (+current.quantite * +current.pvu) }, 0));
            setQteVendu(stocks?.reduce((previous, current) => { return previous + (+current.vendu) }, 0));
            setMontantVendu(stocks?.reduce((previous, current) => { return previous + (+current.vendu * +current.pvu) }, 0));
            setQteReste(stocks?.reduce((previous, current) => { return previous + (+current.stock) }, 0));
            setMontantReste(stocks?.reduce((previous, current) => { return previous + (+current.stock * +current.pau) }, 0));
        }
    }, [stocks]);

    console.log(stocks)

    useEffect(() => {
        if (selectedConteneur) {
            setSelected_TotalQte(selectedConteneur?.produits?.reduce((previous, current) => { return previous + (+current.quantite) }, 0));

            setSelected_TotalMontantPAU(selectedConteneur?.produits?.reduce((previous, current) => { return previous + (+current.quantite * +current.pau) }, 0));
            setSelected_TotalMontantPVU(selectedConteneur?.produits?.reduce((previous, current) => { return previous + (+current.quantite * +current.pvu) }, 0));

            setSelected_QteVendu(selectedConteneur?.produits?.reduce((previous, current) => { return previous + (+current.vendu) }, 0));
            setSelected_MontantVendu(selectedConteneur?.produits?.reduce((previous, current) => { return previous + (+current.vendu * +current.pvu) }, 0));

            setSelected_QteReste(selectedConteneur?.produits?.reduce((previous, current) => { return previous + (+current.reste) }, 0));
            setSelected_MontantReste(selectedConteneur?.produits?.reduce((previous, current) => { return previous + (+current.reste * +current.pau) }, 0));
        }
    }, [selectedConteneur]);
    return (
        isloading ? <Loading /> :
            <div className="produit">
                <div className="row">
                    <div className="col-lg-6 h3 text-primary h5"> SITUATION DU STOCK DU MAGASIN #{id}</div>
                </div>
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <a className={`nav-link ${active === 'actuel' ? 'active' : ''}`} onClick={() => setActive('actuel')}>Stock actuel</a>
                    </li>
                    <li className="nav-item">
                        <a className={`nav-link ${active === 'conteneur' ? 'active' : ''}`} onClick={() => setActive('conteneur')}>Stock par conteneur</a>
                    </li>
                    <li className="nav-item">
                        <a className={`nav-link ${active === 'attente' ? 'active' : ''}`} onClick={() => setActive('attente')}>Stock en attente</a>
                    </li>

                    <li className="nav-item">
                        <a className={`nav-link ${active === 'mois' ? 'active' : ''}`} onClick={() => setActive('mois')}>Stock du mois </a>
                    </li>
                </ul>

                {active === 'actuel' &&
                    <div className="row p-0">

                        {stocks?.length ?
                            <div className="table-responsive" >

                                <table className="table table-bordered table-secondary m-0 px-0">
                                    <thead className="table-dark">
                                        <tr className="align-middle text-center">
                                            <th >CONTENEUR</th>
                                            <th >PRODUIT</th>
                                            <th >QTE APPRO</th>
                                            <th >PAU</th>
                                            <th >M.ACHAT</th>
                                            <th >PVU</th>
                                            <th >M. VENTE</th>
                                            <th >QTE VENDU</th>
                                            <th >M.VENDU</th>
                                            <th >QTE RESTE</th>
                                            <th >M.RESTANT</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            stocks.map((stock, i) => (

                                                <tr key={i}>
                                                    <td  >{stock.conteneur?.toUpperCase()}</td>
                                                    <td  >{stock.produit}</td>
                                                    <td >{stock.quantite}</td>
                                                    <td className="bg-warning-subtle">{(+stock.pau).toLocaleString()}</td>
                                                    <td >{(stock.quantite * stock.pau).toLocaleString()}</td>
                                                    <td className="bg-success-subtle">{(+stock.pvu).toLocaleString()}</td>
                                                    <td >{(stock.quantite * stock.pvu).toLocaleString()}</td>
                                                    <td >{stock.vendu}</td>
                                                    <td >{(stock.vendu * stock.pvu).toLocaleString()}</td>
                                                    <td >{stock.stock}</td>
                                                    <td >{(stock.stock * stock.pau).toLocaleString()}</td>

                                                </tr>

                                            ))
                                        }
                                        <tr className="fw-bold">
                                            <td colSpan={2} className="text-center ">TOTAUX</td>
                                            <td>{totalQte}</td>
                                            <td></td>
                                            <td>{totalMontantPAU.toLocaleString()}</td>
                                            <td></td>
                                            <td>{totalMontantPVU.toLocaleString()}</td>
                                            <td>{qteVendu.toLocaleString()}</td>
                                            <td>{MontantVendu.toLocaleString()}</td>
                                            <td>{qteReste.toLocaleString()}</td>
                                            <td>{montantReste.toLocaleString()}</td>
                                        </tr>
                                    </tbody>
                                </table>



                            </div> : <div className="row p-2 justify-content-center bg-danger-subtle text-danger fs-4">Aucun stock disponible pour le moment</div>
                        }
                    </div>
                }

                {active === 'conteneur' &&
                    <div className="row justify-content-between align-items-center mt-2">
                        <div className="col-lg-5 form-group">
                            <select name="" id="" className="form-select"
                                defaultValue={selectedConteneur}
                                onChange={(e) => setSelectedConteneur(conteneurs?.find(c => +c.id === +e.target.value))}>
                                <option value="">Choisir un conteneur</option>
                                {
                                    conteneurs?.map((conte, ind) => (
                                        <option value={conte?.id} key={ind}>
                                            {conte.conteneur?.toUpperCase()}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                        {(selectedConteneur?.produits?.length > 0) && <button className="btn btn-primary w-auto ms-auto my-2" onClick={() => impression(printable.current, 'Landscape', "Situation des produit du conteneur: " + selectedConteneur?.conteneur)}><i className="bx bx-printer"></i></button>}
                        {selectedConteneur ?
                            selectedConteneur?.produits?.length ?
                                <div className="table-responsive p-2 " ref={printable}>
                                    <table className="table table-bordered table-hover table-striped m-0 px-0">
                                        <thead className="table-dark">
                                            <tr className="align-middle text-center">
                                                <th >N°</th>
                                                <th >PRODUIT</th>
                                                <th >QTE APPRO</th>
                                                <th >PAU</th>
                                                <th >MT. ACHAT</th>
                                                <th >PVU</th>
                                                <th >MT. VENTE</th>
                                                <th >QTE VENDU</th>
                                                <th >MONTANT VENDU</th>
                                                <th >QTE RESTE</th>
                                                <th >MONTANT RESTANT</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                selectedConteneur?.produits?.map((prod, ind) => (
                                                    <tr key={ind}>
                                                        <td>{ind + 1}</td>
                                                        <td>{prod.produit?.toUpperCase()}</td>
                                                        <td >{prod.quantite}</td>
                                                        <td className="bg-warning-subtle">{(prod.pau).toLocaleString()}</td>
                                                        <td >{(prod.quantite * prod.pau).toLocaleString()}</td>

                                                        <td className="bg-success-subtle">{(+prod.pvu).toLocaleString()}</td>
                                                        <td >{(prod.quantite * prod.pvu).toLocaleString()}</td>
                                                        <td >{prod.vendu}</td>
                                                        <td >{(prod.vendu * prod.pvu).toLocaleString()}</td>
                                                        <td >{prod.reste}</td>
                                                        <td >{(prod.reste * prod.pau).toLocaleString()}</td>
                                                    </tr>
                                                ))
                                            }
                                            <tr className="border-none text-center fs-3 fw-bold">
                                                <td colSpan={2}>TOTAUX</td>
                                                <td> {selected_totalQte}</td>
                                                <td></td>
                                                <td>{selected_totalMontantPAU?.toLocaleString()} GNF</td>
                                                <td></td>
                                                <td>{selected_totalMontantPVU?.toLocaleString()} GNF</td>

                                                <td >{selected_qteVendu}</td>
                                                <td >{selected_MontantVendu?.toLocaleString()} GNF</td>
                                                <td >{selected_qteReste}</td>
                                                <td >{selected_montantReste?.toLocaleString()} GNF</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div> : <div className="row p-2 mt-2  mx-1 justify-content-center bg-danger-subtle text-danger fs-4">Aucun stock Enregistré pour cette periode</div>
                            : <div className="row p-2 mt-2  mx-1 justify-content-center bg-info-subtle text-primary fs-4">Choisissez un conteneur</div>
                        }
                    </div>
                }

                {active === 'mois' &&
                    <div className="row justify-content-between align-items-center mt-2">
                        <div className="col-lg-5 d-flex gap-1">
                            <div className="form-group  col-4">
                                <input type="date" className="form-control" id="date1" name="date1" defaultValue={firstDay} onChange={(e) => setDate1(e.target.value)} />
                            </div>
                            <div className="form-group col-4 ">
                                <input type="date" className="form-control" id="date2" name="date2" defaultValue={date2} onChange={(e) => setDate2(e.target.value)} />
                            </div>
                            <button onClick={(e) => handleTriByDate(e)} type="submit" className="btn btn-primary col-2 align-self-end"><i className="bx bx-search" ></i></button>
                        </div>
                        {stockMois ?
                            stockMois?.length ?
                                <div className="table-responsive p-2 ">
                                    <table className="table table-white table-hover table-striped table-bordered">
                                        <thead className="table-primary">

                                            <tr>
                                                <th>N°</th>
                                                <th>NUM-CONTENEUR</th>
                                                <th>CONTENEUR</th>
                                                <th>STATUT</th>
                                                <th>DATE APPRO</th>
                                                <th>DATE VALIDATION</th>
                                                <th className="text-end">ACTIONS</th>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            {
                                                stockMois.map((appro, i) => (
                                                    <tr key={i}>
                                                        <td>{i + 1}</td>
                                                        <td>{appro.numero}</td>
                                                        <td>{appro.conteneur}</td>
                                                        <td>{appro.statut ? 'Validé' : 'En attente'}</td>
                                                        <td>{new Date(appro.created_at).toLocaleDateString()}</td>
                                                        <td>{new Date(appro.updated_at).toLocaleDateString()}</td>
                                                        <td className="text-end">
                                                            <NavLink to={`/approvisions/${appro.id}`} state={{ appro }} className="btn btn-primary m-1">Voir stock</NavLink>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div> : <div className="row p-2 mt-2  mx-1 justify-content-center bg-danger-subtle text-danger fs-4">Aucun stock Enregistré pour cette periode</div>
                            : <div className="row p-2 mt-2  mx-1 justify-content-center bg-info-subtle text-primary fs-4">Choisissez une periode</div>
                        }
                    </div>
                }

                {
                    active === 'attente' &&
                    <div className="row p-2 mt-2">
                        {
                            stockAttente?.length ?
                                <div className="table-responsive">
                                    <div className="col-lg-6">
                                        <SearchField />
                                    </div>

                                    <table className="table table-white table-bordered table-hover table-striped table-bordered">
                                        <thead className="table-primary">
                                            <tr>
                                                <th>N°</th>
                                                <th>NUM-CONTENEUR</th>
                                                <th>CONTENEUR</th>
                                                <th>STATUT</th>
                                                <th>DATE APPRO</th>
                                                <th className="text-end">ACTIONS</th>
                                            </tr>

                                        </thead>

                                        <tbody>
                                            {
                                                stockAttente.map((appro, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{appro.numero}</td>
                                                        <td>{appro.conteneur}</td>
                                                        <td>{'En attente'}</td>
                                                        <td>{new Date(appro.created_at).toLocaleDateString()}</td>
                                                        <td className="text-end">
                                                            <NavLink to={`/approvisions/${appro.id}`} state={{ appro }} className="btn btn-primary m-1">Voir stock</NavLink>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>

                                    </table>

                                </div> : <div className="row p-2 justify-content-center bg-danger-subtle text-danger fs-4">Aucun stock en attente</div>
                        }
                    </div>
                }

            </div >

    );
}

export default StockMagasin;